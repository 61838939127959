import axios from 'axios';
import { Navigate ,Link} from 'react-router-dom';
const apiCall = async (route, payload, method) => {
    try {
        const baseUrl = process.env.REACT_APP_BASE_URL;
        const formattedBaseUrl = baseUrl.endsWith('/') ? baseUrl.slice(0, -1) : baseUrl;
        const response = await axios({
            method: method,
            url: `${formattedBaseUrl}${route}`,
            data: payload,
            headers: {
                'Content-Type': 'application/json',
                'cmstoken':localStorage.getItem('cmsToken')
            }
        });
        console.log('--------49',response.status,'---------------49')
        if(response.status == '401'){
            if (window.location.pathname !== '/login') {
                window.location.href = '/login';
            }
            return
        }
        return response
    } catch (error) {
        if (error.response && error.response.status === 401) {
            if (window.location.pathname !== '/login') {
                window.location.href = '/login';
            }
            return;
        }
    }
};
const apiPutCall = async (route, payload, method,params) => {
    try {
        const baseUrl = process.env.REACT_APP_BASE_URL;
        const formattedBaseUrl = baseUrl.endsWith('/') ? baseUrl.slice(0, -1) : baseUrl;
        const response = await axios({
            method: method,
            url: `${formattedBaseUrl}${route}`,
            data: payload,
            params:params,
            headers: {
                'Content-Type': 'application/json',
                'cmstoken':localStorage.getItem('cmsToken')
            }
        });
        console.log('--------49',response.status,'---------------49')
        if(response.status == '401'){
            if (window.location.pathname !== '/login') {
                window.location.href = '/login';
            }
            return
        }
        return response
    } catch (error) {
        if (error.response && error.response.status === 401) {
            if (window.location.pathname !== '/login') {
                window.location.href = '/login';
            }
            return;
        }
    }
};
const apiGetCall = async (route, params) => {
    try {
        const baseUrl = process.env.REACT_APP_BASE_URL;
        
        const formattedBaseUrl = baseUrl.endsWith('/') ? baseUrl.slice(0, -1) : baseUrl;
        const response = await axios({
            method: 'GET',
            url: `${formattedBaseUrl}${route}`,
            params: params,
            headers: {
                'Content-Type': 'application/json',
                'cmstoken': localStorage.getItem('cmsToken')
            }
        });
        if (response.status === 401) {
            window.location.href = '/login';
            return;
        }

        return response;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            if (window.location.pathname !== '/login') {
                window.location.href = '/login';
            }
            return;
        }

        return false;
    }
};

const apiCallForm = async (route, payload, method) => {
    try {
        const baseUrl = process.env.REACT_APP_BASE_URL;
        const formattedBaseUrl = baseUrl.endsWith('/') ? baseUrl.slice(0, -1) : baseUrl;
        const response = await axios({
            method: method,
            url: `${formattedBaseUrl}${route}`,
            data: payload,
            headers: {
                'Content-Type': 'multipart/form-data',
                'cmstoken':localStorage.getItem('cmsToken')
            }
        })
        if(response.status == '401'){
            if (window.location.pathname !== '/login') {
                window.location.href = '/login';
            }
            return
        }
        console.log(response,'---------116')
        return response
    } catch (error) {
        if (error.response && error.response.status === 401) {
            if (window.location.pathname !== '/login') {
                window.location.href = '/login';
            }
            return;
        }
    }
};
export {apiCall,apiCallForm,apiGetCall,apiPutCall};
