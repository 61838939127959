import React, { useState,useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { useUser } from '../../../src/UserContext';
import { useNavigate } from 'react-router-dom';
import Loader  from '../../components/LoadingComponent/Spinner'
import {apiCall,apiCallForm,apiGetCall} from '../../../src/configs/apiMethodCall'
import { ToastContainer, toast } from 'react-toastify';
function BannerForm(props) {
    const navigate = useNavigate();
    const { selectedLanguage } = useUser();
    const [loading, setLoading] = useState(false);
    const [bannerTitle, setBannerTitle] = useState("");
    const [bannerImage, setBannerImage] = useState(null);
    const [errors, setErrors] = useState({});
    const [imageDimensions, setImageDimensions] = useState('');

    const handleImageUpload = async (event, setImage) => {
        const file = event.target.files[0];
        const reader = new FileReader();
            reader.onload = (e) => {
                const img = new Image();
                img.onload = () => {
                    setImageDimensions(img.width+'X'+img.height);
                };
                img.src = e.target.result;
            };
            reader.readAsDataURL(file);
        setImage(file);
    };
    const handleReset = () => {
        setBannerTitle("");
        setBannerImage(null);
        navigate('/subcription/banner');
    };
    const validateForm = (formType) => {
        let formErrors = {};
        return formErrors;
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
    
        // Clear any previous toast notifications
        toast.dismiss();
    
        // Validate required fields
        if (!bannerTitle) {
            toast.error('Banner title is required.');
            return;
        }
        if (!bannerImage) {
            toast.error('Banner image is required.');
            return;
        }
    
        try {
            setLoading(true);
    
            // Prepare form data
            const formData = new FormData();
            formData.append('banner_title', bannerTitle);
            formData.append('banner_image', bannerImage);
            formData.append('banner_sizes', imageDimensions);
            formData.append('lang_id', selectedLanguage?.id);
    
            // API Call
            const response = await apiCallForm('/plan/create_banner', formData, 'POST');
    
            // Handle API response
            if (response?.data?.status) {
                toast.success('Data saved successfully!');
            } else {
                console.error('Error while saving data:', response?.data?.message || 'Unknown error');
                toast.error(response?.data?.message || 'Error while saving data.');
            }
        } catch (error) {
            if (error.response) {
                // Handle server errors
                console.error('Server Error:', error.response);
                toast.error(error.response?.data?.error || 'Server error occurred.');
            } else if (error.request) {
                // Handle network errors
                console.error('Network Error:', error.request);
                toast.error('No response from server. Please check your network connection.');
            } else {
                // Handle unexpected errors
                console.error('Unexpected Error:', error.message);
                toast.error('An unexpected error occurred.');
            }
        } finally {
            // Ensure loading spinner is hidden and navigate to the banner page
            setLoading(false);
            navigate('/subscription/banner');
        }
    };
    
    return (
        <section className="panel add_section">
            <div className="panel-body">
            {loading && <Loader />}
                <form role="form" onSubmit={(event) => handleSubmit(event, 'widgets_ads')} id="widgets_form">
                    <div className="row mt-3">
                        <section className="panel">
                            <header className="panel-heading" style={{ fontWeight: "bold" }}>Add Banner</header>
                            <div className="row mt-2">
                                <div className="form-group col-md-6">
                                    <label htmlFor="banner_title"> Banner Title<span style={{color: "#ff0000",paddingRight: "10px" }}>*</span></label>
                                    <input type="text" className={`form-control ${bannerTitle ? 'filled' : ''}`} id="banner_title" name="banner_title" placeholder="Banner Title" value={bannerTitle} onChange={(e) => setBannerTitle(e.target.value)} />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="banner_image_dark">Banner Image<span style={{color: "#ff0000"}}>*</span></label>
                                    <input type="file" className={`form-control ${bannerImage ? 'filled' : ''}`} id="banner_image_dark" name="lang_image_dark" accept="image/png, image/jpeg" onChange={(e) => handleImageUpload(e, setBannerImage)} />
                                </div>
                            </div>
                        </section>
                        <div className="col-md-12 mt-3">
                            <button type="submit" id="btnSubmit" className="btn btn-primary mr-2" style={{ padding: "15px", lineHeight: "1.5" }}>Save</button>
                            <button type="button" className="btn btn-secondary" onClick={handleReset} style={{ padding: "15px", lineHeight: "1.5" }}>Clear</button>
                        </div>
                    </div>
                </form>
            </div>
            <ToastContainer />
        </section>
    );
}

export default BannerForm;
