import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TextField, Select, MenuItem, Button, IconButton,Pagination,Modal,InputLabel, FormControl,CircularProgress
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import { Edit, Delete, Save, Cancel } from '@mui/icons-material';
import {apiCall,apiCallForm,apiGetCall} from '../../configs/apiMethodCall'
import {Link} from 'react-router-dom'
const ViewContentOrderIcon = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [contents, setContents] = useState([]);

    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        fetchData();
    }, [currentPage]);

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const [editRowId, setEditRowId] = useState(null);
    const [editedContent, setEditedContent] = useState({
    });

    const contentTypeMap = {
        "0": "Text",
        "1": "Photo-Gallery",
        "2": "Video",
        "3": "Podcast",
        '4': "Webstory",
        '5': "Shorts",
        '6': "Live-Blog",
        "51":"Article Short",
        "52":"Video Short",
        "53":"Podcast Short"
      };
      const iconMap = {
        "1":"Show",
        "0":"Hide"
      }
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await apiGetCall(`/content/content_order_icon_visibility?page=${currentPage}&pageSize=20`,{})
            if (Array.isArray(response.data.data)) {
                setContents(response.data.data);
                setTotalPages(response.data.totalPages);
            } else {
                console.error('Fetched data is not an array:', response.data);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setLoading(false);
    };

 const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };
    const handleEditClick = (id) => {
        setEditRowId(id);
        const content = contents.find(content => content.id === id);
        setEditedContent({ ...content });
        setModalOpen(true);
    };

    const handleCancelClick = () => {
        setEditRowId(null);
        setEditedContent({});
        setModalOpen(false);
    };

    const handleSaveClick = async () => {
        try {
            const response = await apiCall('/content/create_icon_visibility',editedContent,'POST')
                if (response?.data?.status) {
                    setEditRowId(null);
                    setEditedContent({});
                    toast.success('Data updated successfully');
                    fetchData()
                    setModalOpen(false);
                    console.log('Modal should close now');
                } else {
                    console.error('Error updating data:', data.message);
                    alert('Failed to update data');
                }
        } catch (error) {
            alert('Failed to update data');
        }
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditedContent({
            ...editedContent,
            [name]: value
        });
    };
 const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <div style={{ width: '100%', overflowX: 'auto' }}>
    <div className="header-container">
    <header>
        <h1>Icon Visibility</h1>
            </header>
            <div className="add-author-button">
                <Link className="text-white" to="/content/create_icon_visibility">Add Icon Visibility</Link>
            </div>
        </div>
   <TableContainer component={Paper} style={{ width: '100%' }}>
   {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                        <CircularProgress />
                    </div>
                )
   :( <Table>
        <TableHead>	
            <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Article Type</TableCell>
                <TableCell > Icon Visibility</TableCell>
                <TableCell>Created On</TableCell>
                <TableCell>Modified On</TableCell>
                <TableCell>Action</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>
                </TableCell>
                <TableCell>
                </TableCell>
                <TableCell>
                </TableCell>
                <TableCell>
                </TableCell>
                <TableCell>
                </TableCell>
                <TableCell>
                </TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {contents.map((content) => (
                <TableRow key={content.id}>
                    <TableCell>{content.id}</TableCell>
                    <TableCell>{contentTypeMap[content.article_type]}</TableCell>
                    <TableCell>{content.icon_visibility === 1 ? "Show":"Hide"}</TableCell>
                    <TableCell>{new Date(content.created_on*1000).toLocaleString()}</TableCell>
                    <TableCell>{new Date(content.updated_on*1000).toLocaleString()}</TableCell>
                    <TableCell>
                        <IconButton onClick={() => handleEditClick(content.id)} aria-label="edit">
                            <Edit />
                        </IconButton>
                    </TableCell>

                </TableRow>
            ))}
        </TableBody>
    </Table>
    )}
</TableContainer>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px', width: '100%' }}>
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                />
                    <Button
                    variant="outlined"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                >
                    Next
                </Button>
            </div>
            <Modal
                    open={modalOpen}
                    onClose={handleCloseModal}
                    aria-labelledby="edit-content-modal"
                    aria-describedby="modal to edit content"
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                    <section className="panel edit_section col-6">
                        <header className="panel-heading" >
                            Edit Order Icon
                        </header>
                        <div className="panel-body" style={{ maxHeight: '500px', overflowY: 'auto' }}>
                            <form role="form" onSubmit={handleSaveClick} encType="multipart/form-data" id="edit_language_form">
                            <div className="row">
                            <div className="form-group col-md-6">
                                        <label htmlFor="icon_visibility">Icon Visibility</label>
                                        <select
                                            value={editedContent.icon_visibility}
                                            onChange={(e) => handleEditChange({ target: { name: "icon_visibility", value: e.target.value } })}
                                            className={`form-control ${editedContent.icon_visibility ? 'filled' : ''}`}
                                            name="icon_visibility"
                                        >
                                            {Object.keys(iconMap).map((option) => (
                                                <option key={option} value={option}>
                                                    {iconMap[option]}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                <div className="form-group col-md-6">
                                        <label htmlFor="article_type">Article type</label>
                                        <select
                                            value={editedContent.article_type}
                                            onChange={(e) => handleEditChange({ target: { name: "article_type", value: e.target.value } })}
                                            className={`form-control ${editedContent.article_type ? 'filled' : ''}`}
                                            name="article_type"
                                        >
                                            {Object.keys(contentTypeMap).map((option) => (
                                                <option key={option} value={option}>
                                                    {contentTypeMap[option]}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-md-12">
                                            <button type="submit" className="btn btn-primary mr-2" style={{ padding: "15px", lineHeight: "1.5" }}>
                                                Save
                                            </button>
                                            <button type="button" className="btn btn-secondary" onClick={handleCancelClick} style={{ padding: "15px", lineHeight: "1.5" }}>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                            </div>
                            </form>
                            <ToastContainer />
                        </div>
                    </section>

                </Modal>

            </div>
);
};

export default ViewContentOrderIcon;
