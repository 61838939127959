// src/useFeedback.js
import { useState, useEffect } from 'react';
import {apiCall,apiCallForm,apiGetCall} from '../../../src/configs/apiMethodCall'
import { useUser } from '../../../src/UserContext';
const useFeedback = (page, limit) => {
  const { selectedLanguage } = useUser();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
const lang_id = selectedLanguage?.id
  useEffect(() => {
    const langId = selectedLanguage?.id
    const fetchData = async () => {
      setLoading(true);
     const params= {
        page,
        limit,
        langId,
      }
      try {
        const response = await apiGetCall(`/admin/dashboard`,
            params);
        setData(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [page, limit, selectedLanguage]);

  return { data, loading, error };
};

export default useFeedback;
