import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Loader  from '../../components/LoadingComponent/Spinner'
import { useUser } from '../../UserContext';
import {apiCall,apiCallForm,apiGetCall} from '../../../src/configs/apiMethodCall'
function AddTaboolaForm(props) {
    const navigate = useNavigate();
     const { selectedLanguage } = useUser();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [mode, setMode] = useState("");
    const [pageType, setPageType] = useState(null);
    const [pageUrl, setPageUrl] = useState(null);
    const [placementName, setPlacementName] = useState(null)
    const [status, setStatus] = useState(null)
    const [adsCode,setAdsCode] = useState(null)
    const pageTypeMap = {
        "home": "Home",
        "photo": "Photo",
        "article": "Article",
        "video": "Video",
        'live': "Live",
        'category': "Category",
    };
    const statusMap = {
        "1": "Enable",
        "0": "Disable"
    };
    const handleReset = () => {
        setAdsCode("");
        setMode(null);
        setPageType(null);
        setPlacementName(null);
        setPageUrl(null);
        navigate('/master/ads-taboola');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        // Prevent multiple submissions
            // Validate required fields
            if (!mode || !pageType || !pageUrl || !placementName || !status || !adsCode) {
                alert('Missing required fields');
                return;
            }
    
            // Construct payload
          
        
            try {
                if (isSubmitting) return;
                setIsSubmitting(true);
            const payload = {
                mode: mode,
                lang_id: selectedLanguage?.id,
                placement_name: placementName,
                page_url: pageUrl,
                page_type: pageType,
                ads_code: adsCode,
                ads_status: status
            };
    
            // Call the API
            const response = await apiCall('/master/create_taboola_ads', payload, 'POST');
    
            // Handle response
            if (response.data.status) {
                console.log('Data saved successfully');
                toast.success("Data saved successfully");
            } else {
                console.error('Error while saving data:', response.data.message || "Unknown error");
                toast.error(`Error while saving data: ${response.data.message || "Unknown error"}`);
            }
        } catch (error) {
            console.error('Error while saving data:', error);
            toast.error("Error while saving data.");
        } finally {
            setIsSubmitting(false); // Reset the submitting state
            navigate('/master/ads-taboola'); // Navigate after submission
        }
    };
    
    return (
        <section className="panel add_section">
            <header className="panel-heading" >
                Add Taboola
            </header>
            <div className="panel-body">
            {isSubmitting && <Loader />}
                <form role="form" onSubmit={handleSubmit} encType="multipart/form-data" id="layout_form">

                    <div className="row mt-2">
                        <div className="form-group col-md-6">
                            <label htmlFor="placement_name">Placement Name<span style={{color: "#ff0000"}}>*</span></label>
                            <input type="text" className={`form-control ${placementName ? 'filled' : ''}`} id="page_placement_taboola" name="taboola_plav" placeholder="Placement Name" value={placementName} onChange={(e) => setPlacementName(e.target.value)} />
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="layout_code">Ads Code<span style={{color: "#ff0000"}}>*</span></label>
                            <input type="text" className={`form-control ${adsCode ? 'filled' : ''}`} id="taboola_ads_code" name="layout_name" placeholder="Ads Code" value={adsCode} onChange={(e) => setAdsCode(e.target.value)} />
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="form-group col-md-6">
                            <label htmlFor="layout_title">Page Url<span style={{color: "#ff0000"}}>*</span></label>
                            <input type="url" className={`form-control ${pageUrl ? 'filled' : ''}`} id="page_url_taboola" name="taboola_url_page" placeholder="Page Url" value={pageUrl} onChange={(e) => setPageUrl(e.target.value)} />
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="layout_code_mode">Mode/Unit<span style={{color: "#ff0000"}}>*</span></label>
                            <input type="text" className={`form-control ${mode ? 'filled' : ''}`} id="taboola_mode_code" name="mode" placeholder="Mode" value={mode} onChange={(e) => setMode(e.target.value)} />
                        </div>
                    </div>
                      <div className="row mt-3">
                                <div className="form-group col-md-6">
                                    <label htmlFor="menu_page_type">Page Type<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span></label>
                                    <select id="plan_type" className={`form-control ${pageType ? 'filled' : ''}`} value={pageType} onChange={(e) => setPageType(e.target.value)}>
                                        <option value="">--Select a Plan--</option>
                                        {Object.keys(pageTypeMap).map((key) => (
                                            <option key={key} value={key}>
                                                {pageTypeMap[key]}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="menu_status">Status<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span></label>
                                    <select id="status_taboola" className={`form-control ${status ? 'filled' : ''}`} value={status} onChange={(e) => setStatus(e.target.value)}>
                                        <option value="">--Select a status--</option>
                                        {Object.keys(statusMap).map((key) => (
                                            <option key={key} value={key}>
                                                {statusMap[key]}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                    <div className="col-md-12 mt-3">
                        <button type="submit" id="btnSubmit" className="btn btn-primary mr-2" style={{ padding: "15px", lineHeight: "1.5" }}>Save</button>
                        <button type="button" className="btn btn-secondary" onClick={handleReset} style={{ padding: "15px", lineHeight: "1.5" }}>Clear</button>
                    </div>
                </form>
            </div>
            <ToastContainer />
        </section>
    );
}

export default AddTaboolaForm;
