import React, { useState } from 'react';
import axios from 'axios';
import {
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../UserContext';
import Loader  from '../../components/LoadingComponent/Spinner'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Navigate } from 'react-router-dom';
import { apiCall } from '../../configs/apiMethodCall';

const AddAppLabel = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { selectedLanguage } = useUser();
  const [appLabelKey, setAppLabelKey] = useState('');
  const [appLabelValue, setAppLabelValue] = useState('');
  const [screen, setScreen] = useState('');
  const [articleType, setArticleType] = useState('');

  const appLabelScreen = [
    { screen: 1, label: "Article Details" },
    { screen: 2, label: "Listing" },
    { screen: 3, label: "Live Channel" },
    { screen: 4, label: "Notification" },
    { screen: 5, label: "Bookmark" },
    { screen: 6, label: "Home" },
    { screen: 7, label: "Internet Off" },
    { screen: 8, label: "Toasts" },
    { screen: 9, label: "Language" },
    { screen: 10, label: "States" },
    { screen: 11, label: "Categories" },
    { screen: 12, label: "Shorts" },
    { screen: 13, label: "Player" },
    { screen: 14, label: "Left Menu" },
    { screen: 15, label: "Author" },
    { screen: 16, label: "Registration" },
    { screen: 17, label: "Subscription" },
    { screen: 18, label: "Setting" },
    { screen: 19, label: "Search" }
  ];

  const articleTypeMap = [
    { id: 0, label: "Article" },
    { id: 1, label: "Photo-Gallery" },
    { id: 2, label: "Video" },
    { id: 3, label: "Podcast" },
    { id: 4, label: "Web-Story" },
    { id: 5, label: "Short" },
    { id: 6, label: "Liveblog" },
    { id: 7, label: "Live" },
    { id: 51, label: "Article Short" },
    { id: 52, label: "Video Short" },
    { id: 53, label: "Podcast Short" }
  ];

  const handleSubmit = async (event) => {
    event.preventDefault();

    toast.dismiss();

        if (!appLabelKey) {
            toast.error('App Label Key is required.');
            console.log('App Label Key validation failed');
            return;
        }
        if (!appLabelValue) {
            toast.error('App Label Value is required.');
            console.log('App Label Value validation failed');
            return;
        }
        if (!screen) {
            toast.error('Screen is required.');
            console.log('Screen validation failed');
            return;
        }
        if (!articleType) {
            toast.error('Article Type is required.');
            console.log('Article Type validation failed');
            return;
        }
        if (!selectedLanguage?.id) {
            toast.error('Language selection is required.');
            console.log('Language validation failed');
            return;
        }

        // Prepare data for submission
        try {
          if (isSubmitting) return;
            setIsSubmitting(true);
        const data = {
            label_code: appLabelKey,
            label: appLabelValue,
            screen: screen,
            article_type: articleType,
            lang_id: selectedLanguage?.id,
        };

        console.log('Data to be submitted:', data);

        // Submit the data via API
        const response = await apiCall('/master/create_app_label', data, 'POST');
        console.log('API Response:', response);

        if (response && response?.data?.status) {
            toast.success('App label created successfully!');
        } else {
            toast.error('Failed to submit form.');
        }
    } catch (error) {
        console.error('Error submitting form:', error);
        toast.error('Something went wrong while submitting the form.');
    } finally {
        setIsSubmitting(false);
        navigate('/master/app_label');
    }
};



  const handleReset = () => {
    setAppLabelKey('');
    setAppLabelValue('');
    setScreen('');
    setArticleType('');
    navigate('/master/app_label')
  };

  return (
    <>
      <section className="panel add_section">
        <div className="panel-body">
        {isSubmitting && <Loader />}
          <form role="form" onSubmit={handleSubmit} id="widgets_form">
            <header className="panel-heading" style={{ fontWeight: "bold" }}>Add App Label</header>
            <div className="row mt-2">
              <div className="form-group col-md-6">
                <label htmlFor="menu_title"> App Label Key<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span></label>
                <input type="text" className={`form-control ${appLabelKey ? 'filled' : ''}`} id="menu_title" name="menu_title" placeholder="Label key" value={appLabelKey} onChange={(e) => setAppLabelKey(e.target.value)} />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="widgets_category">Screen<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span></label>
                <select className={`form-control ${screen ? 'filled' : ''}`}
                  labelId="select_screen_label"
                  id="select_screen"
                  value={screen}
                  onChange={(e) => setScreen(e.target.value)}>
                  <option value="">--Select a Screen--</option>
                  {appLabelScreen.map((cat) => (
                    <option key={cat.screen} value={cat.screen}>
                      {cat.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row mt-2">
              <div className="form-group col-md-6">
                <label htmlFor="menu_title"> App Label Value<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span></label>
                <input type="text" className={`form-control ${appLabelValue ? 'filled' : ''}`} id="menu_title" name="menu_title" placeholder="Label Value" value={appLabelValue} onChange={(e) => setAppLabelValue(e.target.value)} />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="widgets_category">Article Type<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span></label>
                <select className={`form-control ${articleType ? 'filled' : ''}`}
                  labelId="select_screen_label"
                  id="select_screen"
                  value={articleType}
                  onChange={(e) => setArticleType(e.target.value)}>
                  <option value="">--Select an Article Type--</option>
                  {articleTypeMap.map((cat) => (
                    <option key={cat.id} value={cat.id}>
                      {cat.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-12 mt-3">
              <button type="submit" className="btn btn-primary mr-2" style={{ padding: "15px", lineHeight: "1.5" }}>Save</button>
              <button type="button" className="btn btn-secondary" onClick={handleReset} style={{ padding: "15px", lineHeight: "1.5" }}>Clear</button>
            </div>
          </form>
          <ToastContainer />
        </div>
      </section>
    
    </>
  );
};

export default AddAppLabel;
