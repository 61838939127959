import React, { useState, useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TextField, Select, MenuItem, Button, IconButton, Pagination, Modal, InputLabel, FormControl
} from '@mui/material';
import './config.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Edit } from '@mui/icons-material';
import axios from 'axios';
import { useUser } from '../../../src/UserContext';
import { Link,useNavigate,useParams } from 'react-router-dom';
import {apiCall,apiCallForm,apiGetCall} from '../../../src/configs/apiMethodCall'
const ViewManuSectionList = () => {
    const navigate = useNavigate();
    const { menu_id,menu_side} = useParams();
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const { selectedLanguage } = useUser();

    const handleFilterChange = (event) => {
    };
    const fetchData = () => {
        let payload = {
            "menu_id": menu_id || '',
            "lang_id": selectedLanguage?.id || ''
        };
        console.log('---------29',payload)
        try {
            apiGetCall(`/config/get_menu_section_list`,payload).then((response)=>{
                let data = response?.data?.data;
                    if (Array.isArray(data)) {
                        
                        setData(data);
                    }
            })

        } catch (error) {
            
        }
    };
    useEffect(() => {
        fetchData();
    }, [currentPage, selectedLanguage]);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleCancelClick = (e) => {
        e.preventDefault()
        navigate()
    };
    const handleAddSectionClick = (menu_id) => {
        // navigate(`/config/add_menu_section/${menu_id}/${menuValue}`);
        navigate('/config/view_menu_section/${menu_id}/${menu_side}')
    }
    const handleEditClick = (section_id) => {
        navigate(`/configuration/update_section/${menu_id}/${section_id}`)
    }

    return (
        <>
            <div className="header-container">
                <header>
                </header>
                <div className="add-menu-button">
                <Link to={`/config/add_menu_section/${menu_id}/${menu_side}`}>Add Menu Section</Link>
                </div>
                {/* <div className="add-menu-1-button">
                    <Link to ='/configuration/add_home_settings'>Add Home Settings</Link>
                </div> */}
            </div>
            <TableContainer component={Paper} style={{ width: '100%' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>Section Title</TableCell>
                            <TableCell>Section Type</TableCell>
                            <TableCell>Layout Code</TableCell>
                            <TableCell>Target Element</TableCell>
                            <TableCell>Craeted On</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.length > 0 ? (
                            data.map((content) => (
                                <TableRow key={content.id}>
                                    <TableCell>{content.id}</TableCell>
                                    <TableCell>{content.section_title}</TableCell>
                                    <TableCell>{content.section_type}</TableCell>
                                    <TableCell>{content.layout_code}</TableCell>
                                    <TableCell>{content.target_element}</TableCell>
                                    <TableCell>{new Date(content.created_on * 1000).toLocaleString()}</TableCell>
                                    <TableCell>{content.section_status == 1?'Enabled' :'Disabled'}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleEditClick(content.id)} aria-label="edit">
                                            <Edit />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={7} align="center">No data available</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px', width: '100%' }}>
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                />
                <Button
                    variant="outlined"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                >
                    Next
                </Button>
            </div> */}
        </>
    );
};
export default ViewManuSectionList;