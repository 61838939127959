import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import Loader  from '../../components/LoadingComponent/Spinner'
import { apiCallForm } from '../../configs/apiMethodCall';
import { useUser } from '../../UserContext';
function HeaderIcon(props) {
    const navigate = useNavigate();
      const { selectedLanguage } = useUser();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [iconType, setIconType] = useState("");
    const [enable, setEnable] = useState("");
    const [sortOrder, setSortOrder] = useState("");
    const [langImageDark, setLangImageDark] = useState(null);
    const [langImageLight, setLangImageLight] = useState(null);
    const [previewImageDark, setPreviewImageDark] = useState(null);
    const [previewImageLight, setPreviewImageLight] = useState(null);

const iconTypeMap = {
    "search":"Search",
    "Notification":"Notification",
    "Language":"Language",
    "live_tv":"LIVE TV",
    "game":"Game",
    "subscribe_now":"Subscribe Now"
}
const statusMap = {
    "1":"Enable",
    "0":"Disable"
}
    const handleReset = () => {
        setIconType("");
        setEnable("");
        setSortOrder("");
        setLangImageDark(null);
        setLangImageLight(null);
        navigate('/master/header-top-icon');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        // Clear previous toast messages
        toast.dismiss();
    
            // Validate required fields
            if (!iconType) {
                toast.error('Icon Type is required.');
                return;
            }
            if (!enable) {
                toast.error('Enable status is required.');
                return;
            }
            if (!sortOrder) {
                toast.error('Sort Order is required.');
                return;
            }
            if (!langImageDark) {
                toast.error('Dark Logo is required.');
                return;
            }
            if (!langImageLight) {
                toast.error('Light Logo is required.');
                return;
            }
            if (!selectedLanguage?.id) {
                toast.error('Language selection is required.');
                return;
            }
    
            // Prepare form data for submission
            try {
                if (isSubmitting) return;
                setIsSubmitting(true);
            const formData = new FormData();
            formData.append('icon_type', iconType);
            formData.append('enable', enable);
            formData.append('sort_order', sortOrder);
            formData.append('dark_logo', langImageDark);
            formData.append('light_logo', langImageLight);
            formData.append('cms_id', 0);
            formData.append('lang_id', selectedLanguage?.id);
    
            // Submit the data via API
            const response = await apiCallForm('/master/create_top_header_icon', formData, 'POST');
            if (response && response.data?.status) {
                toast.success('Header icon created successfully!');
            } else {
                toast.error(response.data?.message || 'Failed to submit form.');
            }
        } catch (error) {
            console.error('Error while saving data:', error);
            toast.error('Something went wrong while submitting the form.');
        } finally {
            setIsSubmitting(false);
            navigate('/master/header-top-icon');
        }
    };
    
    
    const handleImageUpload = (event, setImage) => {
        const file = event.target.files[0];
        if (setImage === "dark"){
            setLangImageDark(file)
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setPreviewImageDark(reader.result);
            }
        }
        else{
            setLangImageLight(file)
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setPreviewImageLight(reader.result);
            }
        }
    };

    return (
        <section className="panel add_section">
            <header className="panel-heading">
                Add Language
            </header>
            <div className="panel-body">
              {isSubmitting && <Loader />}
                <form role="form" onSubmit={handleSubmit} encType="multipart/form-data" id="language_form">
                    <div className="row">
                    <div className="form-group col-md-6">
                                    <label htmlFor="menu_page_type">Icon Type<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span></label>
                                    <select id="plan_type" className={`form-control ${iconType ? 'filled' : ''}`} value={iconType} onChange={(e) => setIconType(e.target.value)}>
                                        <option value="">--Select a Icon Type--</option>
                                        {Object.keys(iconTypeMap).map((key) => (
                                            <option key={key} value={key}>
                                                {iconTypeMap[key]}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="menu_status">Status<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span></label>
                                    <select id="status_taboola" className={`form-control ${enable ? 'filled' : ''}`} value={enable} onChange={(e) => setEnable(e.target.value)}>
                                        <option value="">--Select a status--</option>
                                        {Object.keys(statusMap).map((key) => (
                                            <option key={key} value={key}>
                                                {statusMap[key]}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                    </div>
                    <div className="row mt-3">
                        <div className="form-group col-md-6">
                            <label htmlFor="api_token">Sort Order<span style={{color: "#ff0000"}}>*</span></label>
                            <input type="number" className={`form-control ${sortOrder ? 'filled' : ''}`} id="api_token" name="api_token" placeholder="" value={sortOrder} onChange={(e) => setSortOrder(e.target.value)} />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="form-group col-md-6">
                            <label htmlFor="lang_image_dark">Icon Dark<span style={{color: "#ff0000"}}>*</span></label>
                            <input type="file" className={`form-control ${langImageDark ? 'filled' : ''}`}id="lang_image_dark" name="lang_image_dark" accept="image/png, image/jpeg" onChange={(e) => handleImageUpload(e, 'dark')} />
                            {previewImageDark && (
                        <img src={previewImageDark} alt="Lang Image" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
                        )}
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="lang_image_light">Icon Light<span style={{color: "#ff0000"}}>*</span></label>
                            <input type="file" className={`form-control ${langImageLight ? 'filled' : ''}`} id="lang_image_light" name="lang_image_light" accept="image/png, image/jpeg" onChange={(e) => handleImageUpload(e, 'light')} />
                            {previewImageLight && (
                        <img src={previewImageLight} alt="Lang Image" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
                        )}
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <button type="submit" id="btnSubmit" className="btn btn-primary mr-2" style={{ padding: "15px", lineHeight: "1.5" }}>Save</button>
                        <button type="button" className="btn btn-secondary" onClick={handleReset} style={{ padding: "15px", lineHeight: "1.5" }}>Clear</button>
                    </div>
                </form>
                <ToastContainer />
            </div>
        </section>
    );
}

export default HeaderIcon;
