import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TextField, Select, MenuItem, Button, IconButton, Pagination, Modal, InputLabel, FormControl
} from '@mui/material';
import { Link,useNavigate } from 'react-router-dom'; 
import { useUser } from '../../UserContext';
import { Edit, Delete, Save, Add, Cancel } from '@mui/icons-material';
import {apiCall,apiCallForm,apiGetCall} from '../../../src/configs/apiMethodCall'
const ViewAdsList = () => {
    const navigate = useNavigate()
    const { selectedLanguage } = useUser();
    const [contents, setContents] = useState([]);
    const [filters, setFilters] = useState({
        contentType: '',
        lang_id: selectedLanguage?.id,
        status: '',
    });
    const [modalOpen, setModalOpen] = useState(false);

    const handleCloseModal = () => {
        setModalOpen(false);
    };
    // const articleTypeMap = {
    //     "0": "Text",
    //     "1": "Photo-Gallery",
    //     "2": "Video",
    //     "3": "Podcast",
    //     '4': "Webstory",
    //     '5': "Shorts",
    //     '6': "Live-Blog"
    // };

    // const contentTypeMap = {
    //     '0': "",
    //     '1': "Article Short",
    //     '2': "Video Short",
    //     '3': "Podcast Short",
    //     '4': "JW Video Short"
    // };
    const handleEditClick = (id,ad_agency) => {
        console.log(ad_agency,'-------------43')
        if(ad_agency =='DFP'){
            navigate(`/master/edit-ads-list/${id}`);
        }
        else{
            navigate(`/master/edit-ads-list-taboola/${id}`);
        }
    };
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    useEffect(() => {
        if (selectedLanguage?.id) {
            setFilters(prevFilters => ({
                ...prevFilters,
                lang_id: selectedLanguage.id
            }));
        }
    }, [selectedLanguage]);

    useEffect(() => {
        fetchData();
    }, [currentPage, filters]);
    const fetchData = async () => {
        try {
            const response = await apiGetCall(`/master/ads_list?page=${currentPage}&pageSize=20`,filters)
            if (Array.isArray(response.data.data)) {
                setContents(response.data.data);
                setTotalPages(response.data.totalPages);
            } else {
                console.error('Fetched data is not an array:', response.data);
            }
        } catch (error) {
            
        }
    };
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({
            ...filters,
            [name]: value
        });
    };
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };
    const handleDeleteClick = (id) => {

    }
    const [statusOptions, setStatusOptions] = useState([
        { value: false, label: 'All' },
        { value: 1, label: 'Enabled' },
        { value: 0, label: 'Disabled' },
    ]);
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };


    return (
        <>
            <div className="header-container">
                <header>
                    <h1>Ads List</h1>
                </header>
                <div className="add-author-button">
                    <Link className="text-white" to="/master/addads">Add Ads</Link>
                </div>
            </div>

            <div style={{ width: '100%', overflowX: 'auto' }}>
                <TableContainer component={Paper} style={{ width: '100%' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell>Ads Type</TableCell>
                                <TableCell>Ads Display Over</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Ads Code</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                    <Select
                                        value={filters.status}
                                        onChange={handleFilterChange}
                                        displayEmpty
                                        name="status"
                                        size="small"
                                        variant="outlined"
                                    >
                                        {statusOptions.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {contents.map((content) => (
                                <TableRow key={content.id}>
                                    <TableCell>{content.id}</TableCell>
                                    <TableCell>{content.ads_type}</TableCell>
                                    <TableCell>
                                        {content.ads_screen + ' ' + (content.content_type !== '1' ? 'Article' : 'Gallery')}
                                        </TableCell>

                                    <TableCell>{content.ads_status == 1 ? "Enabled" : "Disabled"}</TableCell>
                                    <TableCell>{content.ads_code.length > 20 ? `${content.ads_code.substring(0, 20)}...` : content.ads_code}</TableCell>
                                    <TableCell>
                                    <IconButton onClick={() => handleEditClick(content.id,content.ad_agency)} aria-label="edit">
                                        <Edit />
                                    </IconButton>
                                    <IconButton onClick={() => handleDeleteClick(content.id)} aria-label="delete">
                                        <Delete />
                                    </IconButton>
                                </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px', width: '100%' }}>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                    />
                    <Button
                        variant="outlined"
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </Button>
                </div>
            </div>
        </>
    );
};
export default ViewAdsList;
