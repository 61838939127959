import React, { useState } from 'react';
import { Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Pagination, CircularProgress, Grid } from '@mui/material';
import { Box } from '@mui/system'; // For consistent box layout
import { IoPieChart } from 'react-icons/io5'; // For icon use
import useFeedback from './GetFeedback'; // Assuming custom hook

// Custom BoxWrapper component for consistent card styling
function BoxWrapper({ children }) {
  return (
    <Box
      sx={{
        backgroundColor: 'white',
        borderRadius: '8px',
        padding: '16px',
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #e0e0e0',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        width: '100%',
      }}
    >
      {children}
    </Box>
  );
}

const FeedbackDashboard = () => {
  const [page, setPage] = useState(1);
  const limit = 10;
  const { data, loading, error } = useFeedback(page, limit);

  const handleChange = (event, value) => {
    setPage(value);
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">Error loading data</Typography>;

  return (
    <Container maxWidth="lg" sx={{ marginTop: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Feedback Dashboard
      </Typography>

      {/* Dashboard Summary Boxes - Horizontal Layout */}
      <Grid container spacing={2} sx={{ marginBottom: '20px' }}>
        <Grid item xs={12} sm={6} md={4}>
          <BoxWrapper>
            <div className="rounded-full h-12 w-12 flex items-center justify-center bg-orange-600">
              <IoPieChart className="text-2xl text-white" />
            </div>
            <div className="pl-4">
              <Typography variant="body2" color="textSecondary">
                Total Constituencies
              </Typography>
              <Typography variant="h6" color="textPrimary">
                { data && data.totalConstituencies}
              </Typography>
            </div>
          </BoxWrapper>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <BoxWrapper>
            <div className="rounded-full h-12 w-12 flex items-center justify-center bg-blue-600">
              <IoPieChart className="text-2xl text-white" />
            </div>
            <div className="pl-4">
              <Typography variant="body2" color="textSecondary">
                Total Feedback
              </Typography>
              <Typography variant="h6" color="textPrimary">
                {data && data.totalFeedback}
              </Typography>
            </div>
          </BoxWrapper>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <BoxWrapper>
            <div className="rounded-full h-12 w-12 flex items-center justify-center bg-green-600">
              <IoPieChart className="text-2xl text-white" />
            </div>
            <div className="pl-4">
              <Typography variant="body2" color="textSecondary">
                Feedback Last Week
              </Typography>
              <Typography variant="h6" color="textPrimary">
                {data && data.feedbackLastWeek}
              </Typography>
            </div>
          </BoxWrapper>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <BoxWrapper>
            <div className="rounded-full h-12 w-12 flex items-center justify-center bg-green-600">
              <IoPieChart className="text-2xl text-white" />
            </div>
            <div className="pl-4">
              <Typography variant="body2" color="textSecondary">
                Feedback Last Month
              </Typography>
              <Typography variant="h6" color="textPrimary">
                {data && data.feedbackLastMonth}
              </Typography>
            </div>
          </BoxWrapper>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <BoxWrapper>
            <div className="rounded-full h-12 w-12 flex items-center justify-center bg-green-600">
              <IoPieChart className="text-2xl text-white" />
            </div>
            <div className="pl-4">
              <Typography variant="body2" color="textSecondary">
                Feedback Last Year
              </Typography>
              <Typography variant="h6" color="textPrimary">
                {data && data.feedbackLastYear}
              </Typography>
            </div>
          </BoxWrapper>
        </Grid>
      </Grid>

      {/* Feedback Table */}
      <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Member Name</TableCell>
              <TableCell>Constituency</TableCell>
              <TableCell>Feedback Count</TableCell>
              <TableCell>Positive %</TableCell>
              <TableCell>Negative %</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.feedbackGrouped.map((feedback) => (
              <TableRow key={feedback.member_name}>
                <TableCell>{feedback.member_name}</TableCell>
                <TableCell>{feedback.constituency}</TableCell>
                <TableCell>{feedback.feedback_count}</TableCell>
                <TableCell>{((feedback.positive_count /feedback.feedback_count)*100).toFixed(2)}</TableCell>
                <TableCell>{((feedback.negative_count /feedback.feedback_count)*100).toFixed(2)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <Pagination
        count={data && data.totalPages}
        page={page}
        onChange={handleChange}
        variant="outlined"
        shape="rounded"
        sx={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
      />
    </Container>
  );
};

export default FeedbackDashboard;
