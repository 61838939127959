import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Loader  from '../../components/LoadingComponent/Spinner'
import {apiCall,apiCallForm,apiGetCall} from '../../../src/configs/apiMethodCall'
function LayoutForm(props) {
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [layoutTitle, setLayoutTitle] = useState("");
    const [layoutCode, setLayoutCode] = useState(null);
    const [layoutImage, setLayoutImage] = useState(null);
    const [layoutImagePreview, setLayoutImagePreview] = useState(null)

    const handleReset = () => {
        setLayoutTitle("");
        setLayoutCode(null);
        setLayoutImage(null);
        navigate('/master/layouts');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        // Clear previous toast messages
        toast.dismiss();
    
            // Validate required fields
            if (!layoutCode) {
                toast.error('Layout Code is required.');
                return;
            }
            if (!layoutImage) {
                toast.error('Layout Image is required.');
                return;
            }
            if (!layoutTitle) {
                toast.error('Layout Title is required.');
                return;
            }
    
            // Prepare form data for submission
            try {
                if (isSubmitting) return;
                setIsSubmitting(true);
            const formData = new FormData();
            formData.append('layout_title', layoutTitle);
            formData.append('layout_code', layoutCode);
            formData.append('layout_image', layoutImage);
    
            // Submit the data via API
            const response = await apiCallForm('/master/create_layout', formData, 'POST');
            if (response && response.data?.status) {
                toast.success('Layout created successfully!');
            } else {
                console.error('Error while saving data:', response?.data?.message || 'Unknown error');
                toast.error(response?.data?.message || 'Failed to save layout.');
            }
        } catch (error) {
            console.error('Error while saving data:', error);
            toast.error('Something went wrong while submitting the form.');
        } finally {
            setIsSubmitting(false);
            navigate('/master/layouts');
        }
    };
    
    
    const handleImageUpload = (event, setImage) => {
        const file = event.target.files[0];
        setImage(file);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setLayoutImagePreview(reader.result);
        };
    };

    return (
        <section className="panel add_section">
            <header className="panel-heading" >
                Add Layout
            </header>
            <div className="panel-body">
            {isSubmitting && <Loader />}
                <form role="form" onSubmit={handleSubmit} encType="multipart/form-data" id="layout_form">

                    <div className="row">
                        <div className="form-group col-md-6">
                            <label htmlFor="layout_title">Layout Title<span style={{color: "#ff0000"}}>*</span></label>
                            <input type="text" className={`form-control ${layoutTitle ? 'filled' : ''}`} id="layout_name" name="layout_name" placeholder="Layout Title" value={layoutTitle} onChange={(e) => setLayoutTitle(e.target.value)} />
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="layout_code">Layout Code<span style={{color: "#ff0000"}}>*</span></label>
                            <input type="text" className={`form-control ${layoutCode ? 'filled' : ''}`} id="layout_name" name="layout_name" placeholder="Layout Code" value={layoutCode} onChange={(e) => setLayoutCode(e.target.value)} />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="form-group col-md-6">
                            <label htmlFor="layout_image_dark">Layout Image<span style={{color: "#ff0000"}}>*</span></label>
                            <input type="file" className={`form-control ${layoutImage ? 'filled' : ''}`}id="layout_image_dark" name="layout_image_dark" accept="image/png, image/jpeg" onChange={(e) => handleImageUpload(e, setLayoutImage)} />
                            {layoutImagePreview && (
                        <img src={layoutImagePreview} alt="Layout Image" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
                        )}
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <button type="submit" id="btnSubmit" className="btn btn-primary mr-2" style={{ padding: "15px", lineHeight: "1.5" }}>Save</button>
                        <button type="button" className="btn btn-secondary" onClick={handleReset} style={{ padding: "15px", lineHeight: "1.5" }}>Clear</button>
                    </div>
                </form>
            </div>
            <ToastContainer />
        </section>
    );
}

export default LayoutForm;
