import React, { useState, useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TextField, Select, MenuItem, Button, IconButton, Pagination, Modal, InputLabel, FormControl
} from '@mui/material';
import './config.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Edit, Delete, Save, Add, Cancel } from '@mui/icons-material';
import axios from 'axios';
import { useUser } from '../../../src/UserContext';
import { Link,useNavigate } from 'react-router-dom';
import {apiCall,apiCallForm,apiGetCall} from '../../../src/configs/apiMethodCall'
const ViewAppSettingsList = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [menuValue, setMenuValue] = useState('0');
    const [totalPages, setTotalPages] = useState(1);
    const { selectedLanguage } = useUser();
    const [currentLang, setCurrentLang] = useState(null);
    const [editRowId, setEditRowId] = useState(null);
    const [editedContent, setEditedContent] = useState({});
    const [imagePreviewLight, setImagePreviewLight] = useState(null);
    const [imagePreviewDark, setImagePreviewDark] = useState(null);
    const [imagePreviewSelectedLight, setImagePreviewSelectedLight] = useState(null);
    const [imagePreviewSelectedDark, setImagePreviewSelectedDark] = useState(null);
    const [menuActionElementState, setMenuActionElementState] = useState([]);
    const [menuActionElementData, setMenuActionElementData] = useState([]);
    const [sendToMenu, setSendToMenu] = useState([]);
    const [sendToMenuData, setSendToMenuData] = useState('');
    const [menuType, setMenuType] = useState([]);
    const [menuTypeData, setMenuTypeData] = useState('');
    const [icon, setIcon] = useState('');
    const [darkIcon, setDarkIcon] = useState('');
    const [selectedIcon, setSelectedIcon] = useState('');
    const [selectedDarkIcon, setSelectedDarkIcon] = useState('');

    const fetchMenuActionElement = async (menuType) => {
        try {
            if (!selectedLanguage) {
                toast.error("Please select a language first");
                return;
            }
            const response = await apiGetCall(`/config/get_menu_action_element?lang_id=${selectedLanguage.id}&menu_type=${menuType}`,{});
            console.log('-----------menuType', menuType)
            if (response.data.result) {
                // console.log('-----------38',response.data.result)
                setMenuActionElementState(response.data.result);
            } else {
                setMenuActionElementState([]);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchSendToMenu = async (menuTypeData) => {
        try {
            const response = await apiGetCall(`/config/send_to_menu_list?lang_id=${selectedLanguage.id}`,{});
            console.log('--------------61',response)
            setSendToMenu(response.data.sendToMenu);
            setMenuType(response.data.menuType);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleFilterChange = (event) => {
        setMenuValue(event.target.value);
    };
    const fetchData = async () => {
        // console.log('------------74---call');
        let payload = {
            "menu_side": menuValue || '',
            "lang_id": selectedLanguage?.id || ''
        };
        try {
            const response = await apiCall(`/config/app_setting_minu_list?page=${currentPage}&pageSize=20`,payload,'POST')
             let data = response.data.data;
                // console.log('Fetched data:', data);
                if (Array.isArray(data)) {
                    // console.log('Data is an array:', data);
                    setData(data);
                    setTotalPages(response.data.totalPages || 1);
                } else {
                    console.error('Fetched data is not an array:', data);
                    setData([data]);
                }
        } catch (error) {
            setData([]);
        }
    };

    useEffect(() => {
        console.log('-----------103')
        fetchData();
        // fetchMenuActionElement(editedContent?.menu_type);
        // fetchSendToMenu(editedContent?.menu_type);
    }, [currentPage, menuValue, selectedLanguage]);
    // currentPage, menuValue, selectedLanguage, currentLang, menuTypeData, modalOpen
    const menuFilterOptions = [
        { id: 1, value: "0", label: "Bottom Bar" },
        { id: 3, value: "1", label: "Left Menu" },
        { id: 4, value: "2", label: "Top Menu" },
        { id: 5, value: "3", label: "Left Top Menu" },
        { id: 2, value: "4", label: "Shorts Menu" }
    ];

    const deviceTypeOptions = [
        { id: 1, value: "1", label: "Android" },
        { id: 3, value: "2", label: "Ios" },
    ];
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };
    const handleCloseModal = () => {
        setModalOpen(false);
    };
    const handleCancelClick = () => {
        setEditRowId(null);
        setEditedContent({});
        setModalOpen(false);
        setImagePreviewLight('')
        setImagePreviewDark('')
        setIcon('')
        setDarkIcon('')
        setSelectedIcon('')
        setSelectedDarkIcon('')
        setImagePreviewSelectedLight('')
        setImagePreviewSelectedDark('')

    };
    const handleImageChange = (e, type) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            if (type === "light") {
                setImagePreviewLight(reader.result);
                setIcon(file)
            } else if (type === "dark") {
                setImagePreviewDark(reader.result);
                setDarkIcon(file)
            }
            else if (type === "selectedDark") {
                setImagePreviewSelectedDark(reader.result);
                setSelectedDarkIcon(file)
            }
            else if (type === "selectedLight") {
                setImagePreviewSelectedLight(reader.result);
                setSelectedIcon(file)
            }
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };
    const handleEditClick = async (id) => {
        setEditRowId(id);
        const content = data.find(content => content.id === id);
        console.log('---------80', content)
        setEditedContent({ ...content });
        setModalOpen(true);
        // console.log(tempSendToMenu,'-------176')
        setMenuTypeData(editedContent?.menu_type)
        await fetchSendToMenu(content?.menu_type)
        await fetchMenuActionElement(content?.menu_type)
        const tempSendToMenu = sendToMenu?.filter(row => row.id === content.send_to_menu);
        const tempMenuActionELement = menuActionElementState?.filter(row => row.id == content.menu_action_element)
        setSendToMenuData(tempSendToMenu)
        setMenuActionElementData(tempMenuActionELement)
    };
    const handleEditChange = async (e) => {
        console.log(editedContent,'------------181')
        const { name, value } = e.target;
        setEditedContent({
            ...editedContent,
            [name]: value
        });
        if (name == 'type') {
            console.log('-------------195', name, value)
            await fetchSendToMenu(value)
            await fetchMenuActionElement(value)
        }
    };
    const [statusOptions, setStatusOptions] = useState([
        { value: false, label: 'All' },
        { value: 1, label: 'Enabled' },
        { value: 0, label: 'Disabled' },
    ]);
    const handleSaveClick = async (e) => {
        e.preventDefault()
        const payload = new FormData();
        Object.keys(editedContent).forEach(key => {
            payload.append(key, editedContent[key]);
        });

        if (selectedLanguage && selectedLanguage.id) {
            payload.append('lang_id', String(selectedLanguage.id));
        }
        if (icon) {
            payload.append('icon', icon);
        }
        if (selectedIcon) {
            payload.append('selected_icon', selectedIcon);
        }
        if (selectedDarkIcon) {
            payload.append('selected_dark_icon', selectedDarkIcon);
        }
        if (darkIcon) {
            payload.append('dark_icon', darkIcon);
        }

        try {
            const response = await apiCallForm(`/config/update_menu`,payload,'PUT')

            if (response && response.data.status){
                fetchData()
                setEditRowId(null);
                setEditedContent({});
                toast.success('Data updated successfully');
                setModalOpen(false);
            }
            else{
                toast.error('Failed to update data');
            }
        } catch (error) {
            toast.error('Failed to update data');
        }
    };
    const handleAddSectionClick = (menu_id) => {
        // navigate(`/config/add_menu_section/${menu_id}/${menuValue}`);
        navigate(`/config/view_menu_section/${menu_id}/${menuValue}`)
    }
    return (
        <>
            <div className="header-container">
                <header>
                </header>
                <div className="add-menu-button">
                    <Link to='/configuration/add_app_settings'>Add Menu</Link>
                </div>
                {/* <div className="add-menu-1-button">
                    <Link to ='/configuration/add_home_settings'>Add Home Settings</Link>
                </div> */}
            </div>
            <div style={{ marginBottom: '20px' }}>
                <label htmlFor="filterSelect">Select a Menu:</label>
                <select id="filterSelectMenu" value={menuValue} onChange={handleFilterChange} style={{ marginLeft: '10px' }}>
                    <option value="">Select Menu</option>
                    {menuFilterOptions.map(option => (
                        <option key={option.id} value={option.value}>{option.label}</option>
                    ))}
                </select>
            </div>
            <TableContainer component={Paper} style={{ width: '100%' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>Title</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Value</TableCell>
                            <TableCell>Menu Location</TableCell>
                            <TableCell>Menu Status</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.length > 0 ? (
                            data.map((content) => (
                                <TableRow key={content.id}>
                                    <TableCell>{content.id}</TableCell>
                                    <TableCell>{content.title}</TableCell>
                                    <TableCell>{(typeof content.type !== 'object' ? content.type : content.type[Object.keys(content.type)[0]])}</TableCell>
                                    <TableCell style={{ maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        {content.catName}
                                    </TableCell>
                                    <TableCell>{content.menuSide}</TableCell>
                                    <TableCell>{content.menu_status ? "Enabled" : "Disabled"}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleEditClick(content.id)} aria-label="edit">
                                            <Edit />
                                        </IconButton>
                                        <IconButton onClick={() => handleAddSectionClick(content.id)} aria-label="add">
                                            <Add />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={7} align="center">No data available</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px', width: '100%' }}>
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                />
                <Button
                    variant="outlined"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                >
                    Next
                </Button>
            </div>
            <Modal
                open={modalOpen}
                onClose={handleCloseModal}
                aria-labelledby="edit-content-modal"
                aria-describedby="modal to edit content"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh',
                    paddingLeft: '250px',
                }}
            >
                <section className="panel edit_section">
                    <header className="panel-heading" style={{ color: "#777", fontWeight: "bold", padding: "10px" }}>
                        Edit Menu
                    </header>
                    <div className="panel-body" style={{ maxHeight: '500px', overflowY: 'auto' }}>
                        <form role="form" onSubmit={handleSaveClick} encType="multipart/form-data" id="edit_author_form">
                            <div className="row mt-3">
                                <div className="form-group col-md-6">
                                    <label htmlFor="menu-title">Menu Title*</label>
                                    <input
                                        type="text"
                                        className={`form-control-unified ${editedContent.title ? 'filled' : ''}`}
                                        id="menu-title"
                                        value={editedContent.title}
                                        onChange={(e) => handleEditChange({ target: { name: "title", value: e.target.value } })}
                                        placeholder="Enter Menu Title"
                                        required
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="menu-title">Menu Status</label>
                                    <select
                                            value={editedContent.menu_status}
                                            onChange={(e) => handleEditChange({ target: { name: "menu_status", value: e.target.value } })}
                                            className={`form-control ${editedContent.menu_status ? 'filled' : ''}`}
                                            name="menu_status"
                                        >
                                            {statusOptions.filter(option => option.value !== false).map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="form-group col-md-6">
                                    <label htmlFor="menu-location">Menu Location*</label>
                                    <Select
                                        labelId="menu-location-label"
                                        id="menu-location"
                                        className={`form-control-unified ${editedContent.title ? 'filled' : ''}`}
                                        value={editedContent.menuSide}
                                        onChange={(e) => handleEditChange({ target: { name: "menuSide", value: e.target.value } })}
                                        variant="outlined"
                                        fullWidth
                                        required
                                    >
                                        <MenuItem value={editedContent.menuSide} disabled>
                                            {menuFilterOptions.find(location => location.value === editedContent.menuSide)?.label || editedContent.menuSide}
                                        </MenuItem>
                                        {menuFilterOptions.map(location => (
                                            <MenuItem key={location.id} value={location.value}>
                                                {location.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="menu-type">Menu Type*</label>
                                    <Select
                                        labelId="menu-type-label"
                                        id="menu-type"
                                        className={`form-control-unified ${editedContent.title ? 'filled' : ''}`}
                                        value={editedContent.type}
                                        onChange={(e) => handleEditChange({ target: { name: "type", value: e.target.value } })}
                                        variant="outlined"
                                        fullWidth
                                        required
                                    >
                                        <MenuItem value={editedContent.type} disabled>
                                            {menuType[editedContent.menu_type]}
                                        </MenuItem>
                                        {Object.keys(menuType).map((key) => (
                                            <MenuItem key={key} value={key}>
                                                {menuType[key]}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="form-group col-md-6">
                                    <label htmlFor="send_to_menu">Send To Menu*</label>
                                    <Select
                                        labelId="send_to_menu-label"
                                        id="send_to_menu_data"
                                        className={`form-control-unified ${editedContent.title ? 'filled' : ''}`}
                                        value={editedContent?.send_to_menu}
                                        onChange={(e) => handleEditChange({ target: { name: "send_to_menu", value: e.target.value } })}
                                        variant="outlined"
                                        fullWidth
                                        required
                                    >
                                        {sendToMenu.map((cat) => (
                                            <MenuItem key={cat.id} value={cat.id}>
                                                {cat.title}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="menu-action-element">Menu Action Element*</label>
                                    <Select
                                        labelId="action_element-label"
                                        id="action_element"
                                        className={`form-control-unified ${editedContent.title ? 'filled' : ''}`}
                                        value={editedContent.menu_action_element}
                                        onChange={(e) => handleEditChange({ target: { name: "menu_action_element", value: e.target.value } })}
                                        variant="outlined"
                                        fullWidth
                                        required
                                    >
                                        <MenuItem value={menuActionElementData.length > 0 && menuActionElementData[0]?.id} disabled>
                                            {menuActionElementData.length > 0 && menuActionElementData[0]?.title}
                                        </MenuItem>
                                        {menuActionElementState.map((cat) => (
                                            <MenuItem key={cat.id} value={cat.id}>
                                                {cat.title}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="form-group col-md-6">
                                    <label htmlFor="selected-icon">Selected Icon</label>
                                    <input
                                        type="file"
                                        className={`form-control-unified ${editedContent.imagePreviewSelectedLight ? 'filled' : ''}`}
                                        id="selected-icon"
                                        onChange={(e) => handleImageChange(e, "selectedLight")}
                                    />
                                    {imagePreviewSelectedLight ? (
                                        <img
                                            src={imagePreviewSelectedLight}
                                            alt="Language Dark"
                                            style={{ width: '100px', height: '100px', borderRadius: '50%' }}
                                        />
                                    ) : (
                                        editedContent.selected_icon && (
                                            <img
                                                src={editedContent.selected_icon}
                                                alt="Language Dark"
                                                style={{ width: '100px', height: '100px', borderRadius: '50%' }}
                                            />
                                        )
                                    )}
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="selected-dark-icon">Selected Dark Icon</label>
                                    <input
                                        type="file"
                                        className={`form-control-unified ${editedContent.imagePreviewSelectedDark ? 'filled' : ''}`}
                                        id="selected-dark-icon"
                                        onChange={(e) => handleImageChange(e, "selectedDark")}
                                    />
                                    {imagePreviewSelectedDark ? (
                                        <img
                                            src={imagePreviewSelectedDark}
                                            alt="Language Dark"
                                            style={{ width: '100px', height: '100px', borderRadius: '50%' }}
                                        />
                                    ) : (
                                        editedContent.selected_dark_icon && (
                                            <img
                                                src={editedContent.selected_dark_icon}
                                                alt="Language Dark"
                                                style={{ width: '100px', height: '100px', borderRadius: '50%' }}
                                            />
                                        )
                                    )}
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="form-group col-md-6">
                                    <label htmlFor="icon-light">Icon Light</label>
                                    <input
                                        type="file"
                                        className={`form-control-unified ${editedContent.imagePreviewLight ? 'filled' : ''}`}
                                        id="icon-light"
                                        onChange={(e) => handleImageChange(e, "light")}
                                    />
                                    {imagePreviewLight ? (
                                        <img
                                            src={imagePreviewLight}
                                            alt="Language Dark"
                                            style={{ width: '100px', height: '100px', borderRadius: '50%' }}
                                        />
                                    ) : (
                                        editedContent.icon && (
                                            <img
                                                src={editedContent.icon}
                                                alt="Language Dark"
                                                style={{ width: '100px', height: '100px', borderRadius: '50%' }}
                                            />
                                        )
                                    )}
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="icon-dark">Dark Icon</label>
                                    <input
                                        type="file"
                                        className={`form-control-unified ${editedContent.imagePreviewDark ? 'filled' : ''}`}
                                        id="icon-dark"
                                        onChange={(e) => handleImageChange(e, "dark")}
                                    />
                                    {imagePreviewDark ? (
                                        <img
                                            src={imagePreviewDark}
                                            alt="Language Dark"
                                            style={{ width: '100px', height: '100px', borderRadius: '50%' }}
                                        />
                                    ) : (
                                        editedContent.dark_icon && (
                                            <img
                                                src={editedContent.dark_icon}
                                                alt="Language Dark"
                                                style={{ width: '100px', height: '100px', borderRadius: '50%' }}
                                            />
                                        )
                                    )}
                                </div>
                            </div>
                            <div className='row mt-3'>
                                {editedContent.menu_type === '6' && (
                                    <div className="form-group col-md-6">
                                        <label htmlFor="menu-location">Device Type</label>
                                        <Select
                                            labelId="device-type-label"
                                            id="device_type"
                                            value={editedContent.device_type}
                                            onChange={(e) => handleEditChange({ target: { name: "device_type", value: e.target.value } })}
                                            label="Device Type"
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {deviceTypeOptions.map((cat) => (
                                                <MenuItem key={cat.id} value={cat.value}>
                                                    {cat.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </div>
                                )}

                            </div>
                            <div className="row mt-3">
                                <div className="col-md-12">
                                    <button type="submit" className="btn btn-primary mr-2" style={{ padding: "15px", lineHeight: "1.5" }}>
                                        Save
                                    </button>
                                    <button type="button" className="btn btn-secondary" onClick={handleCancelClick} style={{ padding: "15px", lineHeight: "1.5" }}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            </Modal>
        </>
    );
};
export default ViewAppSettingsList;