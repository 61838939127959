import React, { useState,useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Loader  from '../../components/LoadingComponent/Spinner'
import {apiCall,apiCallForm,apiGetCall} from '../../../src/configs/apiMethodCall'
import { ToastContainer, toast } from 'react-toastify';
import { duration } from '@mui/material';
function AddPlanForm(props) {
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [planType, setPlanType] = useState("");
    const [contentType, setContentType] = useState('')
    const [duration, setDuration] = useState('')
    const [adsType, setAdsType] = useState("");
    const [androidKey, setAndroidKey] = useState("");
    const [iosKey, setIosKey] = useState("");
    const [upgrade, setUpgrade] = useState("");
    const [geoLocation, setGeoLocation] = useState("");
    const [noOfDevice, setNoOfDevice] = useState("");
    const [gst, setGST] = useState("");
    const [isGST, setIsGST] = useState("");
    const [actualPrice, setActualPrice] = useState("");
    const [strikePrice, setStrikePrice] = useState("");
    const [errors, setErrors] = useState({});
    const planTypeMap = {
        "0": "Weekly",
        "1": "Monthly",
        "2": "Quarterly",
        "3": "Half Yearly",
        '4': "Annual",
        '5': "Life Time",
    };
    const durationMap = {
        "7": "7 Days",
        "30": "30 Days",
        "90": "90 Days",
        '365': "365 Days",
        "0": "Perpetuity",
    };
    
    const geoLocationMap = {
        "1": "India",
        "2": "Outside India",
    }
    const contentAccessMap = {
        '1': "Article",
        '2': "Article + Video"
    };
    const AdsTypeMap = {
        '1': "All",
        '2': "Only static type ads",
        '3':'None'
    };
    const gstMap = {
        '1':'Include',
        '2':'Exclude'
    }
    const upgradeMap = {
        '1':'Yes',
        '2':'No'
    }
    const premiumContentTypeMap = {
        0: "Non Premium Content",
        1: "Premium Content"
    }
    const handleImageUpload = (event, setImage) => {
        const file = event.target.files[0];
        setImage(file);
    };
    const handleReset = () => {
        
    };
    const validateForm = (formType) => {
        let formErrors = {};
        return formErrors;
    };
    const handlePlanTypeChange  = (e)=>{
        setPlanType(e.target.value)
    }
    const handleContentAccessnChange = (e)=>{
        setContentType(e.target.value)
    }
    const handleDurationChange = (e)=>{
        setDuration(e.target.value)
    }
    const handleGeoLocationChange =(e)=>{
        setGeoLocation(e.target.value)
    }
    const handleAdsTypeChange =(e)=>{
        setAdsType(e.target.value)
    }
    const handleUpgradeChange = (e)=>{
        setUpgrade(e.target.value)
    }
    const handleMenuLocationChange =(e)=>{
        setGeoLocation(e.target.value)
    }
    const handleIsGSTChange =(e)=>{
        setIsGST(e.target.value)
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
    
        // Clear previous notifications
        toast.dismiss();
    
        // Validate required fields
        if (
            !planType ||
            !contentType ||
            !duration ||
            !adsType ||
            !androidKey ||
            !iosKey ||
            !upgrade ||
            !geoLocation ||
            !noOfDevice ||
            !gst ||
            !isGST ||
            !actualPrice ||
            !strikePrice
        ) {
            toast.error("All fields are required.");
            return;
        }
    
    
        try {
            if (isSubmitting) return;
            setIsSubmitting(true);
    
            // Prepare payload
            const payload = {
                plan_type: planType,
                content_type: contentType,
                duration: duration,
                ad_types_disabled: adsType,
                android_product_key: androidKey,
                ios_product_key: iosKey,
                upgrade: upgrade,
                geo_location: geoLocation,
                no_of_devices: noOfDevice,
                gst_percentage: gst,
                gst_include: isGST,
                plan_amount: actualPrice,
                strike_off_amount: strikePrice,
                platforms: 0,
                position: 0,
                plan_status: 1,
                app_id: 74,
            };
    
            // API call
            const response = await apiCall('/plan/create_plan', payload, 'POST');
    
            // Handle response
            if (response?.data?.status) {
                console.log('Data saved successfully');
                toast.success("Data saved successfully");
                navigate('/subscription/plans');
            } else {
                console.error('Error while saving data:', response?.data?.message || "Unknown error");
                toast.error(response?.data?.message || "Error while saving data.");
            }
        } catch (error) {
            if (error.response) {
                // Handle server errors
                console.error('Server Error:', error.response);
                toast.error(error.response?.data?.error || "Server error occurred.");
            } else if (error.request) {
                // Handle network errors
                console.error('Network Error:', error.request);
                toast.error("No response from server. Please check your network connection.");
            } else {
                // Handle unexpected errors
                console.error('Unexpected Error:', error.message);
                toast.error("An unexpected error occurred.");
            }
        } finally {
            setIsSubmitting(false);
        }
    };
    
    return (
        <section className="panel add_section">
            <div className="panel-body">
            {isSubmitting && <Loader />}
                <form role="form" onSubmit={(event) => handleSubmit(event, 'widgets_ads')} id="widgets_form">
                    <div className="row mt-3">
                        <section className="panel">
                            <header className="panel-heading" style={{ fontWeight: "bold" }}>Add Plan</header>
                            <div className="row mt-2">
                                <div className="form-group col-md-6">
                                    <label htmlFor="menu_title">Plan Type<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span></label>
                                    <select id="plan_type" className={`form-control ${planType ? 'filled' : ''}`} value={planType} onChange={handlePlanTypeChange}>
                                        <option value="">--Select a Plan--</option>
                                        {Object.keys(planTypeMap).map((key) => (
                                            <option key={key} value={key}>
                                                {planTypeMap[key]}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="menu_title">Geo Location<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span></label>
                                    <select id="geo_location" className={`form-control ${geoLocation ? 'filled' : ''}`} value={geoLocation} onChange={handleGeoLocationChange}>
                                        <option value="">--Select a Geo location--</option>
                                        {Object.keys(geoLocationMap).map((key) => (
                                            <option key={key} value={key}>
                                                {geoLocationMap[key]}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="form-group col-md-6">
                                    <label htmlFor="banner_title"> Actual Price<span style={{color: "#ff0000",paddingRight: "10px" }}>*</span></label>
                                    <input type="text" className={`form-control ${actualPrice ? 'filled' : ''}`} id="actual_price" name="actual_price" placeholder="Actual Price" value={actualPrice} onChange={(e) => setActualPrice(e.target.value)} />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="banner_title"> Strike-Off Price<span style={{color: "#ff0000",paddingRight: "10px" }}>*</span></label>
                                    <input type="text" className={`form-control ${strikePrice ? 'filled' : ''}`} id="strike_off" name="strike_off" placeholder="Strike-Off Price" value={strikePrice} onChange={(e) => setStrikePrice(e.target.value)} />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="form-group col-md-6">
                                    <label htmlFor="banner_title">Number of Devices*<span style={{color: "#ff0000",paddingRight: "10px" }}>*</span></label>
                                    <input type="text" className={`form-control ${noOfDevice ? 'filled' : ''}`} id="no_off_device" name="no_off_device" placeholder="No Of Device" value={noOfDevice} onChange={(e) => setNoOfDevice(e.target.value)} />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="banner_title"> GST %<span style={{color: "#ff0000",paddingRight: "10px" }}>*</span></label>
                                    <input type="text" className={`form-control ${gst ? 'filled' : ''}`} id="gst" name="gst" placeholder="GST %" value={gst} onChange={(e) => setGST(e.target.value)} />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="form-group col-md-6">
                                    <label htmlFor="menu_title">Is GST include<span style={{ color: "#ff0000", paddingRight: "10px" }}></span></label>
                                    <select id="widget_category" className={`form-control ${isGST ? 'filled' : ''}`} value={isGST} onChange={handleIsGSTChange}>
                                        <option value="">--Select a Plan--</option>
                                        {Object.keys(gstMap).map((key) => (
                                            <option key={key} value={key}>
                                                {gstMap[key]}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="menu_title">Upgrade<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span></label>
                                    <select id="widget_category" className={`form-control ${upgrade ? 'filled' : ''}`} value={upgrade} onChange={handleUpgradeChange}>
                                        <option value="">--Select a Geo location--</option>
                                        {Object.keys(upgradeMap).map((key) => (
                                            <option key={key} value={key}>
                                                {upgradeMap[key]}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="form-group col-md-6">
                                    <label htmlFor="menu_title">Duration<span style={{ color: "#ff0000", paddingRight: "10px" }}></span></label>
                                    <select id="widget_category" className={`form-control ${duration ? 'filled' : ''}`} value={duration} onChange={handleDurationChange}>
                                        <option value="">--Select a Plan--</option>
                                        {Object.keys(durationMap).map((key) => (
                                            <option key={key} value={key}>
                                                {durationMap[key]}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="menu_title">Content Access<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span></label>
                                    <select id="widget_category" className={`form-control ${contentType ? 'filled' : ''}`} value={contentType} onChange={handleContentAccessnChange}>
                                        <option value="">--Select a Content Access--</option>
                                        {Object.keys(contentAccessMap).map((key) => (
                                            <option key={key} value={key}>
                                                {contentAccessMap[key]}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="form-group col-md-4">
                                    <label htmlFor="menu_title">Ad Types Disabled<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span></label>
                                    <select id="widget_category" className={`form-control ${adsType ? 'filled' : ''}`} value={adsType} onChange={handleAdsTypeChange}>
                                        <option value="">--Select a Plan--</option>
                                        {Object.keys(AdsTypeMap).map((key) => (
                                            <option key={key} value={key}>
                                                {AdsTypeMap[key]}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="banner_title">Android Product Key<span style={{color: "#ff0000",paddingRight: "10px" }}></span></label>
                                    <input type="text"className={`form-control ${androidKey ? 'filled' : ''}`} id="android_key" name="android_key" placeholder="Android Product Key" value={androidKey} onChange={(e) => setAndroidKey(e.target.value)} />
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="iso_key"> IOS Product Key<span style={{color: "#ff0000",paddingRight: "10px" }}></span></label>
                                    <input type="text" className={`form-control ${iosKey ? 'filled' : ''}`} id="ios_key" name="ios_key" placeholder="IOS Product Key" value={iosKey} onChange={(e) => setIosKey(e.target.value)} />
                                </div>
                            </div>
                        </section>
                        <div className="col-md-12 mt-3">
                            <button type="submit" id="btnSubmit" className="btn btn-primary mr-2" style={{ padding: "15px", lineHeight: "1.5" }}>Save</button>
                            <button type="button" className="btn btn-secondary" onClick={handleReset} style={{ padding: "15px", lineHeight: "1.5" }}>Clear</button>
                        </div>
                    </div>
                </form>
            </div>
            <ToastContainer />
        </section>
    );
}

export default AddPlanForm;
