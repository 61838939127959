import React, { useState, useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TextField, Select, MenuItem, Button, IconButton, Pagination, Modal, InputLabel, FormControl
} from '@mui/material';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link ,useNavigate} from 'react-router-dom';
import {apiCall,apiCallForm,apiGetCall} from '../../../src/configs/apiMethodCall'
const VersionControlList = () => {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [statusOptions, setStatusOptions] = useState([
        { value: false, label: 'All' },
        { value: 1, label: 'Enabled' },
        { value: 0, label: 'Disabled' },
    ]);
    const [deviceType, setDeviceType] = useState([
        { value: false, label: 'All' },
        { value: 2, label: 'iOS' },
        { value: 1, label: 'Android' },
    ]);
    const [filters, setFilters] = useState({
        status: false,
        platform:false

    });
    useEffect(() => {
        console.log('-----------------31')
        fetchData(currentPage);
    }, [currentPage,filters]);

    const fetchData = async (page) => {
            try {
                const response = await  apiGetCall(`/config/version_list?page=${page}&pageSize=20`,filters)
                if (Array.isArray(response.data.data)) {
                    toast.success('Version list fetched successfully')
                    setData(response.data.data);
                    setTotalPages(response.data.totalPages || 1);
                } else {
                    console.error('Fetched data is not an array:');
                }
            } catch (error) {
                
            }
    };
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({
            ...filters,
            [name]: value
        });
    };
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <>
        <div className="header-container">
                <header>
                    <h1>Versions</h1>
                </header>
                <div className="add-author-button">
                    <Link className="text-white" to="/configuration/add_api_version">Add Api Version</Link>
                </div>
            </div>

            <div style={{ width: '100%', overflowX: 'auto' }}>
                <TableContainer component={Paper} style={{ width: '100%' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Version</TableCell>
                                <TableCell>Device Type</TableCell>
                                <TableCell>API Encryption Key</TableCell>
                                <TableCell>API Bearer Token</TableCell>
                                <TableCell>Min Version</TableCell>
                                <TableCell>Force Update</TableCell>
                                <TableCell>Version Status</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                <Select
                                        value={filters.device_type}
                                        onChange={handleFilterChange}
                                        displayEmpty
                                        name="platform"
                                        size="small"
                                        variant="outlined"
                                    >
                                        {deviceType.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell>
                                    <Select
                                        value={filters.status}
                                        onChange={handleFilterChange}
                                        displayEmpty
                                        name="status"
                                        size="small"
                                        variant="outlined"
                                    >
                                        {statusOptions.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((content) => (
                                <TableRow key={content.id}>
                                    <TableCell>{content.version}</TableCell>
                                    <TableCell>{content.platform === 2 ? "iOS" : "Android"}</TableCell>
                                    <TableCell>{content.api_enc_key}</TableCell>
                                    <TableCell>{content.api_bearer_token}</TableCell>
                                    <TableCell>{content.min_version}</TableCell>
                                    <TableCell>{content.min_version}</TableCell>
                                    <TableCell>{content.version_status ===1 ? "Active" : "Deprecated"}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px', width: '100%' }}>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                    />
                    <Button
                        variant="outlined"
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </Button>
                </div>
            </div>
        </>
    );
};

export default VersionControlList;
