import React, { useContext, useState, useEffect } from 'react';
// import { UserContext } from './UserContext';

import { Navigate, useNavigate } from "react-router-dom";
import LoadingComponent from "./../components/LoadingComponent/LoadingComponent";
import FeedbackDashboard from './adminManagement/FeedbackDashboard';
// import Swal from "sweetalert2";

function Dashboard() {
//   const userContext = useContext(UserContext);
const [data, setData] = useState([]);
  const navigate = useNavigate();

  

  return (
    <>
      <div>
          <div className="mb-5 flex items-center justify-between">
              {/* <div>
                <h2 className="mb-1.5 text-title-md2 font-bold text-black dark:text-white">Dashboard</h2>
                <p className="font-medium">&nbsp;</p>
              </div> */}
              <div>
                <FeedbackDashboard/>
              </div>
          </div>
       </div>
    </>
  );
}

export default Dashboard;
