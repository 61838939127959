import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TextField, Select, MenuItem, Button, IconButton, Pagination, Modal, InputLabel, FormControl
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Edit, Delete, Save, Add, Cancel } from '@mui/icons-material';
import Loader from '../../components/LoadingComponent/Spinner'
import { useUser } from '../../UserContext';
import { Link, useHistory } from 'react-router-dom';
import { apiCall, apiCallForm, apiGetCall } from '../../../src/configs/apiMethodCall'
const ViewHeaderTopIconList = () => {
      const { selectedLanguage } = useUser();
    const [contents, setContents] = useState([]);
    const [imagePreviewLight, setImagePreviewLight] = useState(null);
    const [imagePreviewDark, setImagePreviewDark] = useState(null);
    const [editRowId, setEditRowId] = useState(null);
    const [editedContent, setEditedContent] = useState({
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [icon, setIcon] = useState('');
    const [darkIcon, setDarkIcon] = useState('');
    const [filters, setFilters] = useState({
        category: '',
        contentType: '',
        status: false,
        fromDate: '',
        toDate: ''
    });

    const [modalOpen, setModalOpen] = useState(false);

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const iconTypeMap = {
        "search": "Search",
        "Notification": "Notification",
        "Language": "Language",
        "live_tv": "LIVE TV",
        "game": "Game",
        "subscribe_now": "Subscribe Now"
    }
    const statusMap = {
        "1": "Enable",
        "0": "Disable"
    }
    const [statusOptions, setStatusOptions] = useState([
        { value: false, label: 'All' },
        { value: 1, label: 'Enabled' },
        { value: 0, label: 'Disabled' },
    ]);
    useEffect(() => {
        fetchData();
    }, [currentPage, filters]);
    const fetchData = async () => {
        try {
            const response = await apiGetCall(`/master/header_top_icon_list?page=${currentPage}&pageSize=20`, filters)
            if (Array.isArray(response.data.data)) {
                setContents(response.data.data);
                setTotalPages(response.data.totalPages);
            } else {
                console.error('Fetched data is not an array:', response.data);
            }
        } catch (error) {

        }
    };
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({
            ...filters,
            [name]: value
        });
    };
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };
    const handleEditClick = (id) => {
        setEditRowId(id);
        const content = contents.find(content => content.id === id);
        console.log('---------80', content)
        setImagePreviewLight(content?.light_logo)
        setImagePreviewDark(content?.dark_logo)
        setEditedContent({ ...content });
        setModalOpen(true);
        console.log(editedContent, '-------------------103')
    };

    const handleCancelClick = () => {
        setEditRowId(null);
        setEditedContent({});
        setModalOpen(false);
        setImagePreviewLight('')
        setImagePreviewDark('')
        setIcon('')
        setDarkIcon('')
    };

    const handleSaveClick = async (e) => {
        e.preventDefault()
        let formData = new FormData();
        Object.keys(editedContent).forEach(key => {
            try {
                const value = editedContent[key];
                formData.append(key, value);
            } catch (error) {
                console.log('Error appending key to formData:', error);
            }
        });

        if (icon) {
            try {
                formData.append('light_logo', icon);
                console.log('Icon appended:', icon);
            } catch (error) {
                console.log('Error appending icon:', error);
            }
        }

        if (darkIcon) {
            try {
                formData.append('dark_logo', darkIcon);
                console.log('Dark icon appended:', darkIcon);
            } catch (error) {
                console.log('Error appending dark icon:', error);
            }
        }
        try {
            const response = await apiCallForm(`/master/update_top_header_icon`, formData, 'PUT')
            if (response && response.status) {
                setEditRowId(null);
                setEditedContent({});
                toast.success('Data updated successfully');
                fetchData()
                setModalOpen(false);
                console.log('Modal should close now');
            } else {
                console.error('Error updating data:', data.message);
                toast.error('Failed to update data');
            }
        } catch (error) {
            toast.error('Failed to update data');
        }
    };

    const handleImageChange = (e, type) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            if (type === "light") {
                setImagePreviewLight(reader.result);
                setIcon(file)
            } else if (type === "dark") {
                setImagePreviewDark(reader.result);
                setDarkIcon(file)
            }
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };


    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditedContent({
            ...editedContent,
            [name]: value
        });
    };
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <>
            <div className="header-container">
                <header>
                    <h1>Top Header Icons</h1>
                </header>
                <div className="add-author-button">
                    <Link className="text-white" to="/master/add_header-top-icon">Add Header</Link>
                </div>
            </div>

            <div style={{ width: '100%', overflowX: 'auto' }}>
                <TableContainer component={Paper} style={{ width: '100%' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell>Icon Type</TableCell>
                                <TableCell>Sort Order</TableCell>
                                <TableCell>Icon Light</TableCell>
                                <TableCell>Icon Dark</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Created On</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                    <Select
                                        value={filters.status}
                                        onChange={handleFilterChange}
                                        displayEmpty
                                        name="status"
                                        size="small"
                                        variant="outlined"
                                    >
                                        {statusOptions.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {contents.map((content) => (
                                <TableRow key={content.id}>
                                    <TableCell>{content.id}</TableCell>
                                    <TableCell>{content.icon_type}</TableCell>
                                    <TableCell>{content.sort_order}</TableCell>
                                    <TableCell>{content.light_logo && (
                                        <img src={content.light_logo} alt="Lang Logo" style={{ width: '50px', height: '50px' }} />
                                    )}</TableCell>
                                    <TableCell>{content.dark_logo && (
                                        <img src={content.dark_logo} alt="Lang Logo" style={{ width: '50px', height: '50px' }} />
                                    )}</TableCell>
                                    {/* <TableCell>{content.designation}</TableCell> */}
                                    <TableCell>{content.enable == 1 ? "Enabled" : "Disabled"}</TableCell>
                                    <TableCell>{new Date(content.created_on * 1000).toLocaleString()}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleEditClick(content.id)} aria-label="edit">
                                            <Edit />
                                        </IconButton>
                                    </TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px', width: '100%' }}>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                    />
                    <Button
                        variant="outlined"
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </Button>
                </div>

                <Modal
                    open={modalOpen}
                    onClose={handleCloseModal}
                    aria-labelledby="edit-content-modal"
                    aria-describedby="modal to edit content"
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                    <section className="panel edit_section col-6">
                        <header className="panel-heading">
                            Edit Language
                        </header>
                        <div className="panel-body" style={{ maxHeight: '500px', overflowY: 'auto' }}>
                            <form role="form" onSubmit={handleSaveClick} encType="multipart/form-data" id="edit_language_form">
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="icon_type">Icon Type</label>
                                        <select id="icon_type" className={`form-control ${editedContent.icon_type ? 'filled' : ''}`} value={editedContent.icon_type} onChange={(e) => handleEditChange({ target: { name: "icon_type", value: e.target.value } })}>
                                            <option value="">--Select a Icon Type--</option>
                                            {Object.keys(iconTypeMap).map((key) => (
                                                <option key={key} value={key}>
                                                    {iconTypeMap[key]}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="lang_status">Status</label>
                                        <select id="status_taboola" className={`form-control ${editedContent.enable ? 'filled' : ''}`} value={editedContent.enable} onChange={(e) => handleEditChange({ target: { name: "enable", value: e.target.value } })}>
                                            <option value="">--Select a status--</option>
                                            {Object.keys(statusMap).map((key) => (
                                                <option key={key} value={key}>
                                                    {statusMap[key]}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="api_token">Sort Order<span style={{ color: "#ff0000" }}>*</span></label>
                                        <input
                                            type="number"
                                            className={`form-control ${editedContent.sort_order ? 'filled' : ''}`}
                                            id="api_token"
                                            name="api_token"
                                            placeholder=""
                                            value={editedContent.sort_order}
                                            onChange={(e) => handleEditChange({ target: { name: "sort_order", value: e.target.value } })}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="state-image-light">Logo Light (Image Ratio 1:1)</label>
                                        <input
                                            id="lang-image-light"
                                            type="file"
                                            className="form-control"
                                            onChange={(e) => handleImageChange(e, "light")}
                                        />
                                        {imagePreviewLight && (
                                            <img src={imagePreviewLight} alt="Language Light" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
                                        )}
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="lang-image-dark">Logo Dark (Image Ratio 1:1)</label>
                                        <input
                                            id="lang-image-dark"
                                            type="file"
                                            className="form-control"
                                            onChange={(e) => handleImageChange(e, "dark")}
                                        />
                                        {imagePreviewDark && (
                                            <img src={imagePreviewDark} alt="Language Dark" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
                                        )}
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-12">
                                        <button type="submit" className="btn btn-primary mr-2" style={{ padding: "15px", lineHeight: "1.5" }}>
                                            Save
                                        </button>
                                        <button type="button" className="btn btn-secondary" onClick={handleCancelClick} style={{ padding: "15px", lineHeight: "1.5" }}>
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </form>
                            <ToastContainer />
                        </div>
                    </section>

                </Modal>
            </div>
        </>
    );
};
export default ViewHeaderTopIconList;
