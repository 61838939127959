import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './config.css'
function ViewSaveSettingsForm(props) {
    const [stickyAdsCode, setStickyAdsCode] = useState("");
    const [layoutCode, setLayoutCode] = useState("");
    const [layoutTitle, setLayoutTitle] = useState("");
    const [listingAdsCode, setListingAdsCode] = useState("");
    const [interstitialAdsCode, setInterstitialAdsCode] = useState('');
    const [interstitialAdsCount ,setinterstitialAdsCount] = useState("")
    const [activeTab, setActiveTab] = useState('Article');
    const [errors, setErrors] = useState({});
    const [enableSubscription, setEnableSubscription] = useState('');
    const handleReset = () => {
        setLayoutTitle("");
        setLayoutCode("");
        window.location.href = '/master/ads';
    };
    const handleTabClick = (e, tabName) => {
        e.preventDefault();
        setActiveTab(tabName);
    };


    const handleSubscriptionChange = (event) => {
        setEnableSubscription(event.target.value);
    };
    const validateForm = (formType) => {
        let formErrors = {};
            
            if (formType === 'sticky_ads') {
                console.log(stickyAdsCode,'-------------27')
                if (!stickyAdsCode) {
                    formErrors.stickyAdsCode = 'Title is required';
                }
            } else if (formType === 'interstitial_ads') {
            } else if (formType === 'listing_ads') {
            }
        return formErrors;
    };
    const handleSubmit = async (event,formType) => {
        event.preventDefault();
        
        const formErrors = validateForm(formType);

        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            alert('Please fill in all required fields.');
            // return;
        }
        try {
            const data = {
                stickyAdsCode: stickyAdsCode,
            };
            console.log(formType,'---------------999')
            const response = await fetch('data', {
                method: 'POST',
                body: data
            });
    
            if (response.ok) {
                window.location.href = '/master/ads';
                toast.success('Ads added successfully!', {
                  });
            } else {
                console.error('Error while saving data:', response.statusText);
            }
        } catch (error) {
            console.error('Error while saving data:', error);
        }
    };

    return (
        <section className="panel add_section">
        <div className="panel-body">
            <form role="form" onSubmit={(event) => handleSubmit(event, 'sticky_ads')} id="sticky_ads_form">
                {/* <div className="col-md-12 error bold alert-box">
                </div> */}
                <div>
                    <section className="panel">
                        <header className="panel-heading">Subscription</header>
                        <div className="row mt-2">
                            <div className="form-group col-md-6">
                                <label className="bold">Enable Subscription</label>
                                <div>
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        id="enable"
                                        name="enable_subscription"
                                        value="1"
                                        checked={enableSubscription === '1'}
                                        onChange={handleSubscriptionChange}
                                    />
                                    <label className="form-check-label" htmlFor="enable">Yes</label>
                                    <span className="error bold"></span>
                                </div>
                                <div>
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        id="disable"
                                        name="enable_subscription"
                                        value="0"
                                        checked={enableSubscription === '0'}
                                        onChange={handleSubscriptionChange}
                                    />
                                    <label className="form-check-label" htmlFor="disable">No</label>
                                    <span className="error bold"></span>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </form>

            <form role="form" onSubmit={(event) => handleSubmit(event, 'interstitial_ads')} id="interstitial_ads_form">
                <div className="row mt-3">
                    <section className="panel">
                        <header className="panel-heading">Update App Data</header>
                        <div className="row mt-2">
                            <div className="form-group col-md-6">
                                <label htmlFor="article_cms_id">Article CMS ID<span style={{color: "#ff0000",paddingRight: "10px" }}></span></label>
                                <input type="text" className="form-control" id="article_cms_id" name="article_cms_id" placeholder="Enter CMS ID" value={interstitialAdsCode} onChange={(e) => setInterstitialAdsCode(e.target.value)} />
                            </div>
                        </div>
                    </section>
                    <div className="col-md-12 mt-3">
                        <button type="submit" id="btnSubmit" className="btn btn-primary mr-2">Update</button>
                        <button type="button" className="btn btn-secondary" onClick={handleReset}>Clear</button>
                    </div>
                </div>
            </form>

            <form role="form" onSubmit={(event) => handleSubmit(event, 'listing_ads')} id="listing_ads_form">
                <div className="row mt-3">
                    <section className="panel">
                        <header className="panel-heading">S3 Account</header>
                        <div className="row mt-2">
                            <div className="form-group col-md-4">
                                <label htmlFor="interstitial_ads">Secret Key<span style={{color: "#ff0000",paddingRight: "10px" }}>*</span></label>
                                <input type="text" className="form-control" id="interstitial_name" name="interstitial_name" placeholder="Interstitial Ads" value={interstitialAdsCode} onChange={(e) => setInterstitialAdsCode(e.target.value)} />
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="interstitial_ads">Access Key<span style={{color: "#ff0000",paddingRight: "10px" }}>*</span></label>
                                <input type="text" className="form-control" id="interstitial_name" name="interstitial_name" placeholder="Interstitial Ads" value={interstitialAdsCode} onChange={(e) => setInterstitialAdsCode(e.target.value)} />
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="interstitial_ads">Bucket Name<span style={{color: "#ff0000",paddingRight: "10px" }}>*</span></label>
                                <input type="text" className="form-control" id="interstitial_name" name="interstitial_name" placeholder="Interstitial Ads" value={interstitialAdsCode} onChange={(e) => setInterstitialAdsCode(e.target.value)} />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="form-group col-md-4">
                                <label htmlFor="interstitial_ads">CloudFront<span style={{color: "#ff0000",paddingRight: "10px" }}>*</span></label>
                                <input type="text" className="form-control" id="interstitial_name" name="interstitial_name" placeholder="Interstitial Ads" value={interstitialAdsCode} onChange={(e) => setInterstitialAdsCode(e.target.value)} />
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="interstitial_ads">Region<span style={{color: "#ff0000",paddingRight: "10px" }}>*</span></label>
                                <input type="text" className="form-control" id="interstitial_name" name="interstitial_name" placeholder="Interstitial Ads" value={interstitialAdsCode} onChange={(e) => setInterstitialAdsCode(e.target.value)} />
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="interstitial_ads">Cognito ID<span style={{color: "#ff0000",paddingRight: "10px" }}>*</span></label>
                                <input type="text" className="form-control" id="interstitial_name" name="interstitial_name" placeholder="Interstitial Ads" value={interstitialAdsCode} onChange={(e) => setInterstitialAdsCode(e.target.value)} />
                            </div>
                        </div>
                    </section>
                    <div className="col-md-12 mt-3">
                        <button type="submit" id="btnSubmit" className="btn btn-primary mr-2">Save</button>
                        <button type="button" className="btn btn-secondary" onClick={handleReset}>Clear</button>
                    </div>
                </div>
            </form>

            <form role="form" onSubmit={(event) => handleSubmit(event, 'listing_ads')} id="listing_ads_form">
                <div className="row mt-3">
                    <section className="panel">
                        <header className="panel-heading">Firebase Credentials</header>
                        <div className="row mt-2">
                            <div className="form-group col-md-6">
                                <label htmlFor="interstitial_ads">Select Mode<span style={{color: "#ff0000",paddingRight: "10px" }}>*</span></label>
                                <input type="text" className="form-control" id="interstitial_name" name="interstitial_name" placeholder="Interstitial Ads" value={interstitialAdsCode} onChange={(e) => setInterstitialAdsCode(e.target.value)} />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="interstitial_ads">GSM KEY<span style={{color: "#ff0000",paddingRight: "10px" }}>*</span></label>
                                <input type="text" className="form-control" id="interstitial_name" name="interstitial_name" placeholder="Interstitial Ads" value={interstitialAdsCode} onChange={(e) => setInterstitialAdsCode(e.target.value)} />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="form-group col-md-6">
                                <label htmlFor="interstitial_ads">FIREBASE API KEY<span style={{color: "#ff0000",paddingRight: "10px" }}>*</span></label>
                                <input type="text" className="form-control" id="interstitial_name" name="interstitial_name" placeholder="Interstitial Ads" value={interstitialAdsCode} onChange={(e) => setInterstitialAdsCode(e.target.value)} />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="interstitial_ads">Database url<span style={{color: "#ff0000",paddingRight: "10px" }}>*</span></label>
                                <input type="text" className="form-control" id="interstitial_name" name="interstitial_name" placeholder="Interstitial Ads" value={interstitialAdsCode} onChange={(e) => setInterstitialAdsCode(e.target.value)} />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="form-group col-md-6">
                                <label htmlFor="interstitial_ads">Json<span style={{color: "#ff0000",paddingRight: "10px" }}>*</span></label>
                                <textarea rows="10" cols="50" class="form-control  editor " id="json" name="json" value={interstitialAdsCode} onChange={(e) => setInterstitialAdsCode(e.target.value)}></textarea>
                                {/* <input type="text" className="form-control" id="interstitial_name" name="interstitial_name" placeholder="Interstitial Ads" value={interstitialAdsCode} onChange={(e) => setInterstitialAdsCode(e.target.value)} /> */}
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="interstitial_ads">Service Account<span style={{color: "#ff0000",paddingRight: "10px" }}>*</span></label>
                                <input type="text" className="form-control" id="interstitial_name" name="interstitial_name" placeholder="Interstitial Ads" value={interstitialAdsCode} onChange={(e) => setInterstitialAdsCode(e.target.value)} />
                            </div>
                        </div>
                    </section>
                    <div className="col-md-12 mt-3">
                        <button type="submit" id="btnSubmit" className="btn btn-primary mr-2">Save</button>
                        <button type="button" className="btn btn-secondary" onClick={handleReset}>Clear</button>
                    </div>
                </div>
            </form>
        </div>
        <ToastContainer />
    </section>
    );
}

export default ViewSaveSettingsForm;
