import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, FormControl, InputLabel, TextField } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useUser } from '../../UserContext';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader  from '../../components/LoadingComponent/Spinner'
import { apiCall, apiCallForm, apiGetCall } from '../../../src/configs/apiMethodCall'
const UpdateFormTaboolaMain = () => {
    const navigate = useNavigate()
    const { selectedLanguage } = useUser();
    const { id } = useParams();
    const [filters, setFilters] = useState({
        lang_id: selectedLanguage?.id,
        id: id,
        status: ''
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [data, setData] = useState({})
    const pageTypeMap = {
        "home": "Home",
        "photo": "Photo",
        "article": "Article",
        "video": "Video",
        'live': "Live",
        'category': "Category",
    };
    const statusMap = {
        "1": "Enable",
        "0": "Disable"
    };

    const [langId, setLangId] = useState('')
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiGetCall(`/master/ads_list`, filters);
                console.log(response,'-----------45353')
                let dataFromApi = response.data.data?.[0];
                setData(dataFromApi)
                console.log(dataFromApi,'------------------45')
                setLangId(data?.lang_id);
            } catch (error) {
                console.error('Error fetching form data:', error);
            }
        };

        fetchData();
    }, [id, filters]);

    // Handle form submission
    const handleSubmit = async (event) => {
           event.preventDefault();
           if (isSubmitting) return;
           setIsSubmitting(true); 
           try {
               const response = await apiCall('/master/create_taboola_ads',data,'POST')
               if (response.data.status) {
                   console.log('Data saved successfully');
                   toast.success("Data saved successfully", {
                   });
               } else {
                   console.error('Error while saving data:', "Error while saving data:");
                   alert("Error while saving data:");
               }
           } catch (error) {
               alert("Error while saving data:")
   
           }finally{
               setIsSubmitting(false)
               navigate('/master/ads-taboola');
           }
       };

    const handleReset = () => {
    };
    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        });
    }

    return (
        <section className="panel add_section">
            <header className="panel-heading" >
                Add Layout
            </header>
            <div className="panel-body">
            {isSubmitting && <Loader />}
                <form role="form" onSubmit={handleSubmit} encType="multipart/form-data" id="layout_form">

                    <div className="row mt-2">
                        <div className="form-group col-md-6">
                            <label htmlFor="placement_name">Placement Name<span style={{color: "#ff0000"}}>*</span></label>
                            <input type="text" className={`form-control ${data?.placement ? 'filled' : ''}`} id="page_placement_taboola" name="taboola_plav" placeholder="Placement Name" value={data?.placement} onChange={(e) => handleEditChange({ target: { name: "placement", value: e.target.value } })} />
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="layout_code">Ads Code<span style={{color: "#ff0000"}}>*</span></label>
                            <input type="text" className={`form-control ${data?.ads_code ? 'filled' : ''}`} id="taboola_ads_code" name="layout_name" placeholder="Ads Code" value={data?.ads_code} onChange={(e) => handleEditChange({ target: { name: "ads_code", value: e.target.value } })} />
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="form-group col-md-6">
                            <label htmlFor="layout_title">Page Url<span style={{color: "#ff0000"}}>*</span></label>
                            <input type="url" className={`form-control ${data?.page_url ? 'filled' : ''}`} id="page_url_taboola" name="taboola_url_page" placeholder="Page Url" value={data?.page_url} onChange={(e) => handleEditChange({ target: { name: "page_url", value: e.target.value } })} />
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="layout_code_mode">Mode/Unit<span style={{color: "#ff0000"}}>*</span></label>
                            <input type="text" className={`form-control ${data?.mode ? 'filled' : ''}`} id="taboola_mode_code" name="mode" placeholder="Mode" value={data?.mode} onChange={(e) => handleEditChange({ target: { name: "mode", value: e.target.value } })}  />
                        </div>
                    </div>
                      <div className="row mt-3">
                                <div className="form-group col-md-6">
                                    <label htmlFor="menu_page_type">Page Type<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span></label>
                                    <select id="plan_type" className={`form-control ${data?.page_type ? 'filled' : ''}`} value={data?.page_type} onChange={(e) => handleEditChange({ target: { name: "page_type", value: e.target.value } })} >
                                        <option value="">--Select a Plan--</option>
                                        {Object.keys(pageTypeMap).map((key) => (
                                            <option key={key} value={key}>
                                                {pageTypeMap[key]}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="menu_status">Status<span style={{ color: "#ff0000", paddingRight: "10px" }}>*</span></label>
                                    <select id="status_taboola" className={`form-control ${data?.ads_status ? 'filled' : ''}`} value={data?.ads_status ? 1 : 0} onChange={(e) => handleEditChange({ target: { name: "ads_status", value: e.target.value } })} >
                                        <option value="">--Select a status--</option>
                                        {Object.keys(statusMap).map((key) => (
                                            <option key={key} value={key}>
                                                {statusMap[key]}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                    <div className="col-md-12 mt-3">
                        <button type="submit" id="btnSubmit" className="btn btn-primary mr-2" style={{ padding: "15px", lineHeight: "1.5" }}>Save</button>
                        <button type="button" className="btn btn-secondary" onClick={handleReset} style={{ padding: "15px", lineHeight: "1.5" }}>Clear</button>
                    </div>
                </form>
            </div>
            <ToastContainer />
        </section>
    );
};

export default UpdateFormTaboolaMain;
