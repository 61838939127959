import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TextField, Select, MenuItem, Button, IconButton, Pagination, Modal, InputLabel, FormControl
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Edit, Delete, Save, Add, Cancel } from '@mui/icons-material';
import { Link } from 'react-router-dom';

import {apiCall,apiCallForm,apiGetCall} from '../../../src/configs/apiMethodCall'
const ViewLayoutList = () => {
    const [contents, setContents] = useState([]);
    const [filters, setFilters] = useState({
        category: '',
        contentType: '',
        status: false,
        fromDate: '',
        toDate: ''
    });
    const [icon, setIcon] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [imagePreview, setImagePreview] = useState('');
    const [editRowId, setEditRowId] = useState(null);
    const [editedContent, setEditedContent] = useState({
    });
    const [statusOptions, setStatusOptions] = useState([
        { value: false, label: 'All' },
        { value: 1, label: 'Enabled' },
        { value: 0, label: 'Disabled' },
    ]);
    const [modalOpen, setModalOpen] = useState(false);

    const handleCloseModal = () => {
        setModalOpen(false);
    };
    const contentTypeMap = {
        "0": "Text",
        "1": "Photo-Gallery",
        "2": "Video",
        "3": "Podcast",
        '4': "Webstory",
        '5': "Shorts",
        '6': "Live-Blog"
    };

    const articleTypeMap = {
        '0': "Unknown",
        '1': "Article Short",
        '2': "Video Short",
        '3': "Podcast Short",
        '4': "JW Video Short"
    };
    const premiumContentTypeMap = {
        0: "Non Premium Content",
        1: "Premium Content"
    }


    useEffect(() => {
        fetchData();
    }, [currentPage, filters]);
    const fetchData = async () => {
        try {
            const response = await apiGetCall(`/master/layout_list?page=${currentPage}&pageSize=20`,filters)
            if (Array.isArray(response.data.data)) {
                    setContents(response.data.data);
                    setTotalPages(response.data.totalPages);
                } else {
                    console.error('Fetched data is not an array:', response.data);
                }
        } catch (error) {
            
        }
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({
            ...filters,
            [name]: value
        });
    };
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };
    const handleEditClick = (id) => {
        setEditRowId(id);
        const content = contents.find(content => content.id == id);
        console.log('---------80', content)
        setEditedContent({ ...content });
        setImagePreview(content.layout_image)
        setModalOpen(true);
        console.log(editedContent, '-------------------103')
    };

    const handleCancelClick = () => {
        setEditRowId(null);
        setEditedContent({});
        setModalOpen(false);
    };

    const handleSaveClick = async(e) => {
        e.preventDefault()
        let formData = new FormData();
        Object.keys(editedContent).forEach(key => {
            try {
                const value = editedContent[key];
                formData.append(key, value);
            } catch (error) {
                console.log('Error appending key to formData:', error);
            }
        });

        if (icon) {
            try {
                formData.append('layout_image', icon);
            } catch (error) {
                console.log('Error appending icon:', error);
            }
        }
        console.log(formData, '------------------134')
        try {
            const response = await apiCallForm(`/master/update_layout`,formData,'PUT')
            if (response && response.data.status) {
                            setEditRowId(null);
                            setEditedContent({});
                            toast.success('Data updated successfully');
                            fetchData()
                            setModalOpen(false);
                            console.log('Modal should close now');
                        } else {
                            console.error('Error updating data:', data.message);
                            toast.error('Failed to update data');
                        }
        } catch (error) {
            toast.error('Failed to update data');
        }
    };
    const handleImageChange = (e, type) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setImagePreview(reader.result);
            setIcon(file)
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };
    const handleDeleteClick = (id) => {
        // Delete data via API
        fetch(`https://your-api-endpoint.com/contents/${id}`, {
            method: 'DELETE'
        })
            .then(() => {
                const updatedContents = contents.filter(content => content.id !== id);
                setContents(updatedContents);
            })
            .catch(error => console.error('Error deleting data:', error));
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditedContent({
            ...editedContent,
            [name]: value
        });
    };
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <>
            <div className="header-container">
                <header>
                    <h1>Layout List</h1>
                </header>
                <div className="add-author-button">
                    <Link className="text-white" to="/master/addlayout">Add Layout</Link>
                </div>
            </div>

            <div style={{ width: '100%', overflowX: 'auto' }}>
                <TableContainer component={Paper} style={{ width: '100%' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell>Layout Code</TableCell>
                                <TableCell>Layout Image</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Created On</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                    <Select
                                        value={filters.status}
                                        onChange={handleFilterChange}
                                        displayEmpty
                                        name="status"
                                        size="small"
                                        variant="outlined"
                                    >
                                        {statusOptions.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {contents.map((content) => (
                                <TableRow key={content.id}>
                                    <TableCell>{content.id}</TableCell>
                                    {/* <TableCell>{content.cms_id}</TableCell> */}
                                    <TableCell>{content.layout_code}</TableCell>
                                    <TableCell>{content.layout_image && (
                                        <img src={content.layout_image} alt="Layout Image" style={{ width: '50px', height: '50px' }} />
                                    )}</TableCell>
                                    {/* <TableCell>{content.designation}</TableCell> */}
                                    <TableCell>{content.layout_status === 1 ? "Enabled" : "Disabled"}</TableCell>
                                    <TableCell>{new Date(content.created * 1000).toLocaleString()}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleEditClick(content.id)} aria-label="edit">
                                            <Edit />
                                        </IconButton>
                                        <IconButton onClick={() => handleDeleteClick(content.id)} aria-label="delete">
                                            <Delete />
                                        </IconButton>
                                    </TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px', width: '100%' }}>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                    />
                    <Button
                        variant="outlined"
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </Button>
                </div>
                <Modal
                    open={modalOpen}
                    onClose={handleCloseModal}
                    aria-labelledby="edit-content-modal"
                    aria-describedby="modal to edit content"
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                    <section className="panel edit_section col-6">
                        <header className="panel-heading" >
                            Edit Layout
                        </header>
                        <div className="panel-body" style={{ maxHeight: '500px', overflowY: 'auto' }}>
                            <form role="form" onSubmit={handleSaveClick} encType="multipart/form-data" id="edit_language_form">
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="layout_title">Layout Title<span style={{ color: "#ff0000" }}>*</span></label>
                                        <input
                                            type="text"
                                            className={`form-control ${editedContent.layout_title ? 'filled' : ''}`}
                                            id="layout_title"
                                            name="layout_title"
                                            placeholder="Layout Title"
                                            value={editedContent.layout_title}
                                            onChange={(e) => handleEditChange({ target: { name: "layout_title", value: e.target.value } })}
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="layout_status">Layout Status</label>
                                        <select
                                            value={editedContent.layout_status}
                                            onChange={(e) => handleEditChange({ target: { name: "layout_status", value: e.target.value } })}
                                            className={`form-control ${editedContent.layout_status ? 'filled' : ''}`}
                                            name="layout_status"
                                        >
                                           {statusOptions.filter(option => option.value !== false).map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="state-image-light">Layout Image (Image Ratio 1:1)</label>
                                        <input
                                            id="layout-image-light"
                                            type="file"
                                            className="form-control"
                                            onChange={(e) => handleImageChange(e, "light")}
                                        />
                                        {imagePreview && (
                                            <img src={imagePreview} alt="Language Light" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
                                        )}
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="layout_code">Layout Code<span style={{ color: "#ff0000" }}>*</span></label>
                                        <input
                                            type="text"
                                            className={`form-control ${editedContent.layout_code ? 'filled' : ''}`}
                                            id="layout_code"
                                            name="layout_code"
                                            placeholder="Layout Code"
                                            value={editedContent.layout_code}
                                            onChange={(e) => handleEditChange({ target: { name: "layout_code", value: e.target.value } })}
                                        />
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-md-12">
                                            <button type="submit" className="btn btn-primary mr-2" style={{ padding: "15px", lineHeight: "1.5" }}>
                                                Save
                                            </button>
                                            <button type="button" className="btn btn-secondary" onClick={handleCancelClick} style={{ padding: "15px", lineHeight: "1.5" }}>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <ToastContainer />
                        </div>
                    </section>
                </Modal>
            </div>
        </>
    );
};
export default ViewLayoutList;
